
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LessonContents extends Vue {
    @Prop() colorCode!: string
    youtubeUrl = 'https://www.youtube.com/embed/'

    lessonContentTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/lesson-content-title.png'
    lessonContentSubtitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/lesson-content-subtitle.png'
    lessonContentPicture =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/lesson-content-picture.png'
    lessonContentOrder =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/lesson-content-order.png'
    signUpUrlAdult =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/CTA02.png'

    isAdultPage() {
        return this.$route.path.includes('/adult')
    }
}
