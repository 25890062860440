export interface AuthenticationState {
    token?: string
    email?: string
    eventMessage?: string
    errorMessage?: string
    a8?: string
    name?: string
}

export const initialState = (): AuthenticationState => {
    return {
        token: undefined,
        email: undefined,
        eventMessage: undefined,
        errorMessage: undefined,
        a8: undefined,
        name: undefined,
    }
}
