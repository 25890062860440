
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class Instruction extends Vue {
    TermsofUse = 'https://blog.musioenglish.com/musio-english%E4%BC%9A%E5%93%A1%E8%A6%8F%E7%B4%84/'
    CommercialTransactions =
        'https://blog.musioenglish.com/%e7%89%b9%e5%ae%9a%e5%95%86%e5%8f%96%e5%bc%95%e6%b3%95%e3%81%ab%e5%9f%ba%e3%81%a5%e3%81%8f%e8%a1%a8%e7%a4%ba/'
    PrivacyPolicy =
        'https://blog.musioenglish.com/musio%e3%83%97%e3%83%a9%e3%82%a4%e3%83%90%e3%82%b7%e3%83%bc%e3%83%9d%e3%83%aa%e3%82%b7%e3%83%bc/'

    goTo(url: string): void {
        window.open(url, '_blank')
    }
}
