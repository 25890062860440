
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/authentication/types'
import { ActionTypes as CustomerActions } from '../store/modules/customer/types'
import PasswordField from '../components/PasswordField.vue'
import i18n from '@/plugins/i18n'

@Component({
    components: {
        PasswordField,
    },
})
export default class Login extends Vue {
    valid = false
    email = ''
    password = ''
    requiredRule = [(v: string) => !!v || i18n.t('thisFieldIsRequired')]

    async onLoginClicked(): Promise<void> {
        if (this.valid) {
            const auth = await this.$store.dispatch(ActionTypes.LOGIN_REQUEST, {
                email: this.email,
                password: this.password,
            })
            if (auth.token) {
                await this.$store.dispatch(CustomerActions.GET_ORDER_HISTORY_REQUEST)
                this.$router.push((this.$route.query.redirect as string) || '/mypage')
            }
        }
    }
}
