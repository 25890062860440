
import { Vue, Component } from 'vue-property-decorator'
import Timeline from './Timeline.vue'
import TimelineEsatJ from './TimeLineEsatJ.vue'

@Component({
    components: {
        Timeline,
        TimelineEsatJ,
    },
})
export default class TimeLineTab extends Vue {
    get activeTab() {
        const routePath = this.$route.path
        // 라우팅 주소에 따라 다른 값을 반환
        if (routePath === '/timeline') {
            return 'kids'
        } else if (routePath === '/timeline/adult') {
            return 'adult'
        } else if (routePath === '/timeline/musio_esatj') {
            return 'esat-j'
        } else {
            return ''
            // 필요에 따라 기본값을 추가하거나 다른 경우를 처리할 수 있습니다.
        }
    }
}
