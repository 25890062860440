
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MediaCoverage extends Vue {
    mediaTitleUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_media_title.png'
    mediaImageUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_media_image.jpg'
    mediaImageUrl2 =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_media_image2.jpg'
    mediaTitleUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/web_media_title.png'
    mediaImageUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/web_media_image.jpg'
}
