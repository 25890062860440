
import { Vue, Component, Watch } from 'vue-property-decorator'
import LogoutButton from './LogoutButton.vue'

@Component({
    components: {
        LogoutButton,
    },
})
export default class Navbar extends Vue {
    navSignupUrl =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/menu-signup-icon.png'
    navLoginUrl =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/menu-login-icon.png'
    navLogoUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/ME_logo.svg'
    blogUrl = 'https://blog.musioenglish.com'
    noticeUrl =
        'https://blog.musioenglish.com/musio-esat-j%e3%81%ab%e9%96%a2%e3%81%99%e3%82%8b%e3%81%8a%e7%9f%a5%e3%82%89%e3%81%9b/'
    navLogoMobileUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/ME_logo.svg'
    timelineUrl = '/timeline/kids'
    supportUrl = '/support'
    planTable = '/plantable'
    main = '/'
    QnAUrl = 'https://blog.musioenglish.com/?page_id=2168'
    drawer = false
    contents = [
        { title: 'login', url: '/login' },
        { title: 'join', url: '/plan' },
        { title: 'timeline', url: '/timeline/kids' },
        { title: 'blog', url: this.blogUrl },
        {
            title: 'faq',
            url:
                'https://blog.musioenglish.com/sango%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/',
        },
        {
            title: 'notice',
            url:
                'https://blog.musioenglish.com/musio-esat-j%e3%81%ab%e9%96%a2%e3%81%99%e3%82%8b%e3%81%8a%e7%9f%a5%e3%82%89%e3%81%9b/',
        },
    ]

    // not mypage navbar
    showNormalNavbar = true

    jumpFromMobile(content: any) {
        if (content.title === 'blog' || content.title === 'faq' || content.title === 'notice') {
            this.goTo(content.url)
        } else {
            this.navigate(content.url)
        }
    }
    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
    navigateLOGO(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
        window.location.reload()
    }

    goTo(url: string): void {
        window.open(url, '_blank')
    }

    scrollMove(to: string) {
        // 他のページからホームアイコンをクリックした場合
        if (to == '/' && this.$router.currentRoute.path !== '/') {
            this.navigate(to)
        } else {
            if (to == '/') {
                this.$vuetify.goTo(0)
            } else {
                this.$vuetify.goTo(to)
            }
        }
    }

    get menuShow() {
        return (
            this.$route.path === '/' ||
            this.$route.path.includes('/campaign') ||
            this.$route.path.includes('/timeline') ||
            this.$route.path.includes('/kids') ||
            this.$route.path.includes('/adult') ||
            this.$route.path.includes('/musio_esatj')
        )
    }

    get isSlashPlan() {
        return this.$route.path.includes('/plan')
    }

    get isSlashTimeline() {
        return this.$route.path.includes('/timeline')
    }

    get showHomeMenu() {
        return (
            this.$route.path.includes('/campaign') ||
            this.$route.path.includes('/plan') ||
            this.$route.path.includes('/timeline')
        )
    }

    // page loaded
    created(): void {
        // pages which mypage related
        this.showNormalNavbar = this.$route.meta?.requiresAuth != true
    }

    // watch page move
    @Watch('$route')
    onRouteChangeReceived(val: any) {
        // pages which mypage related
        this.showNormalNavbar = val.meta?.requiresAuth != true
    }
}
