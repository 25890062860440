import Vue from 'vue'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import App from './App.vue'
import i18n from './plugins/i18n'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueFacebookPixel)

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App),
}).$mount('#app')
