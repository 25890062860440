
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
    goTo(url: string): void {
        window.open(url, '_blank')
    }

    get adultPage() {
        return this.$route.path.includes('/adult')
    }
}
