import Axios from 'axios'

export interface HSFormData {
    user_info: any
}

export async function submit(data: HSFormData) {
    let url =
        'https://api.hsforms.com/submissions/v3/integration/submit/8884732/e7859659-6e51-48e3-8bea-5a7dbdb1f758'
    var hutk = ''
    let cookieArr = document.cookie.split(';')
    for (var str of cookieArr) {
        let pair = str.split('=')
        if (pair[0] == ' hubspotutk') {
            hutk = pair[1]
        }
    }

    let payload = {
        fields: data.user_info,
        context: {
            pageUri:
                'https://www.musioenglish.com//signup-completed?utm_source=hubspot-test&utm_medium=CV&utm_campaign=hubspot_test',
            pageName: document.title,
            hutk: hutk,
        },
        skipValidation: true,
    }
    try {
        await Axios.post(url, payload)
    } catch (err) {
        throw new Error('Fail to post hsform')
    }
}
