import Axios from 'axios'
import { STORE_API_URL, STORE_NOTIFICATION } from '@/utils/urls'

export async function listNotification(token: string): Promise<any> {
    const res = await Axios.get(STORE_API_URL + STORE_NOTIFICATION, {
        headers: { Authorization: `Bearer ${token}` },
    })

    return JSON.parse(res.data.data)
}

export async function createNotification(token: string, data: object): Promise<any> {
    const res = await Axios.post(STORE_API_URL + STORE_NOTIFICATION, data, {
        headers: { Authorization: `Bearer ${token}` },
    })

    return JSON.parse(res.data.data)
}

export async function updateNotification(token: string, data: any): Promise<any> {
    const res = await Axios.put(STORE_API_URL + STORE_NOTIFICATION + '/' + data.id, data, {
        headers: { Authorization: `Bearer ${token}` },
    })

    return JSON.parse(res.data.data)
}
