
import { Vue, Component } from 'vue-property-decorator'
import UserInterviews from '../components/UserInterviews.vue'
import PackageList from '../components/PackageList.vue'
import AdultPackageList from '../components/AdultPackageList.vue'
import AdultMarketingPoints from '../components/AdultMarketingPoints.vue'
import AdultUserInterviews from '../components/AdultUserInterviews.vue'
import OptionBook from './OptionBook.vue'

@Component({
    components: {
        UserInterviews,
        PackageList,
        AdultPackageList,
        AdultMarketingPoints,
        AdultUserInterviews,
        OptionBook,
    },
})
export default class KidsTabs extends Vue {
    isActiveTab = 'kids'

    isSelect(tab: string) {
        this.isActiveTab = tab
    }
}
