
import { Vue, Component } from 'vue-property-decorator'
import PackageContentCommonKidsFamily from '@/components/PackageContentCommonKidsFamily.vue'
import MarketingPoints from './OptionBook.vue'

@Component({
    components: {
        PackageContentCommonKidsFamily,
        MarketingPoints,
    },
})
export default class PackageContentFamily extends Vue {
    packageContentFamilyTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_content_family_title.png'
    packageFamilyContentsEdgeHawaiiWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_family_contents_edge_hawaii.png'
    packageFamilyContentsEdgeBusinessWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_family_contents_edge_business.png'
    packageFamilyContentsNhkWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_family_contents_nhk.png'
    packageFamilyTitleImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+134+(2).png'
    featureMusio =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_2.webp'
    featureKimini =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_1.webp'
    featureSophy =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_3.webp'
    featureAdult =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_4.webp'
    contentsforAdultTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/foradult_title%402x.png'
    contentsforAdultHawaii =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/family_hawaii.webp'
    contentsforAdultBusiness =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/family_business.webp'
    contentsforAdultNhk =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/family_nhk.webp'
}
