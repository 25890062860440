
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes as CustomerActionTypes } from '@/store/modules/customer/types'
import AWSS3UploadAshClient from 'aws-s3-upload-ash'

@Component({
    components: {},
})
export default class SettingStep extends Vue {
    getItem = undefined
    search = ''
    items = []
    loading = true
    showEdit = false
    showAdd = false

    dateEdit = 0
    timeEdit = ''
    contentEdit = ''
    realcontentEdit = ''
    fileEdit: any
    titleEdit = ''
    numEdit = 0

    dateAdd = 0
    timeAdd = ''
    contentAdd = ''
    fileAdd: any
    titleAdd = ''

    file: any
    fileA: any

    headers = [
        { text: 'date', value: 'date' },
        { text: 'time', value: 'time' },
        { text: 'content', value: 'content' },
        { text: 'file', value: 'file' },
        { text: 'title', value: 'title' },
    ]
    async created() {
        await this.$store.dispatch(CustomerActionTypes.GET_STEP_MAIL)

        // eslint-disable-next-line no-console
        // console.log(this.$store.state)
        this.items = this.$store.state.customer.settingStep.map((item: any) => {
            // eslint-disable-next-line no-console
            // console.log(item)
            const items = Object.assign({}, item)
            items.date = item.date
            items.time = item.time
            if (item.content != '' && item.content != null) {
                items.content = 'Html omitted...'
            } else {
                items.content = ''
            }
            items.realcontent = item.content
            items.file = item.file
            items.title = item.title
            items.num = item.num

            return items
        })
    }
    onClickRow(value: any): void {
        // eslint-disable-next-line no-console
        console.log(value)
        this.showEdit = true
        this.dateEdit = value.date
        this.timeEdit = value.time
        this.contentEdit = value.content
        this.realcontentEdit = value.realcontent
        this.fileEdit = value.file
        this.titleEdit = value.title
        this.numEdit = value.num
    }
    onFileChange(e: any) {
        this.file = e.target.files || e.dataTransfer.files
    }
    onFileChangeAdd(e: any) {
        this.fileA = e.target.files || e.dataTransfer.files
    }
    async add() {
        this.showAdd = false

        const config = {
            bucketName: 'usermodel-assets',
            dirName: 'musio-english-assets',
            region: 'ap-northeast-1',
            accessKeyId: process.env.VUE_APP_ACCESSKEYID,
            secretAccessKey: process.env.VUE_APP_SECRETACCESSKEY,
            s3Url: 'https://usermodel-assets.s3.amazonaws.com/',
        }

        if (this.fileA == undefined) {
            this.fileA = ''
        } else {
            let fileDot = this.fileA[0].name.split('.')[1]
            let S3CustomClient = new AWSS3UploadAshClient(config)
            await S3CustomClient.uploadFile(
                this.fileA,
                fileDot,
                undefined,
                this.fileA[0].name,
                undefined
            )
                .then(data => {
                    this.fileAdd = data.key
                    // eslint-disable-next-line no-console
                    console.log(data)
                })
                .catch(err =>
                    // eslint-disable-next-line no-console
                    console.log(err)
                )
        }

        const form = {
            time: this.timeAdd,
            content: this.contentAdd,
            date: this.dateAdd,
            file: this.fileAdd,
            title: this.titleAdd,
        }
        await this.$store.dispatch(CustomerActionTypes.POST_STEP_MAIL, {
            data: form,
        })
        location.reload()
    }

    async onsubmit() {
        this.showEdit = false
        let tmpContent: any

        const config = {
            bucketName: 'usermodel-assets',
            dirName: 'musio-english-assets',
            region: 'ap-northeast-1',
            accessKeyId: process.env.VUE_APP_ACCESSKEYID,
            secretAccessKey: process.env.VUE_APP_SECRETACCESSKEY,
            s3Url: 'https://usermodel-assets.s3.amazonaws.com/',
        }

        if (this.file == undefined) {
            if (this.fileEdit == '' || this.fileEdit == undefined) {
                this.file = ''
            } else {
                this.file = this.fileEdit
            }
        } else {
            let fileDot = this.file[0].name.split('.')[1]
            let S3CustomClient = new AWSS3UploadAshClient(config)
            await S3CustomClient.uploadFile(
                this.file,
                fileDot,
                undefined,
                this.file[0].name,
                undefined
            )
                .then(data => {
                    this.fileEdit = data.key
                    // eslint-disable-next-line no-console
                    console.log(data)
                })
                .catch(err =>
                    // eslint-disable-next-line no-console
                    console.log(err)
                )
        }
        if (this.contentEdit == 'Html omitted...') {
            tmpContent = this.realcontentEdit
        } else {
            tmpContent = this.contentEdit
        }

        const form = {
            num: this.numEdit,
            time: this.timeEdit,
            content: tmpContent,
            date: this.dateEdit,
            file: this.fileEdit,
            title: this.titleEdit,
        }
        await this.$store.dispatch(CustomerActionTypes.PUT_STEP_MAIL, {
            data: form,
        })
        location.reload()
    }

    async ondelete() {
        this.showEdit = false
        const form = {
            num: this.numEdit,
            time: this.timeEdit,
        }
        await this.$store.dispatch(CustomerActionTypes.DELETE_STEP_MAIL, {
            data: form,
        })
        location.reload()
    }
}
