
import { Vue, Component } from 'vue-property-decorator'
import AddressSection from './AddressSection.vue'

@Component({
    components: {
        AddressSection,
    },
})
export default class MyPageShippingAddress extends Vue {}
