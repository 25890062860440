
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FunctionList extends Vue {
    functionTitleUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_function_title.jpg'
    functionDescUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_function_description.png'
    functionImg1Url =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_function_image_1.jpg'
    functionImg2Url =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_function_image_2.jpg'
    functionTitleUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/fun_function_title.png'
    funFunctionDialogWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/fun_function_dialog.png'
    funFunctionSquareWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/fun_function_square.png'
    signUpUrlKids =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/me_trial_v2.png'
    signUpUrlAdult =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/CTA02.png'

    isAdultPage() {
        return this.$route.path.includes('/adult')
    }
}
