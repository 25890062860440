
import { Vue, Component } from 'vue-property-decorator'
import EsatJPartner from '../components/EsatJPartner.vue'
import Introduction from '../components/Introduction.vue'
import Adult from './Adult.vue'
import EsatJ from './EsatJ.vue'
import Kids from './Kids.vue'

@Component({
    components: {
        Introduction,
        Kids,
        Adult,
        EsatJ,
        EsatJPartner,
    },
})
export default class Landing extends Vue {
    get activeTab() {
        const routePath = this.$route.path
        // 라우팅 주소에 따라 다른 값을 반환
        if (routePath === '/') {
            return 'kids'
        } else if (routePath === '/adult') {
            return 'adult'
        } else if (routePath === '/musio_esatj') {
            return 'esat-j'
        } else {
            return ''
            // 필요에 따라 기본값을 추가하거나 다른 경우를 처리할 수 있습니다.
        }
    }
}
