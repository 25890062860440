
import { Vue, Component } from 'vue-property-decorator'
import MarketingPoints from '@/components/MarketingPoints.vue'
import OptionBook from '../components/OptionBook.vue'

@Component({
    components: {
        MarketingPoints,
        OptionBook,
    },
})
export default class PackageContentOriginal extends Vue {
    packageOriginalTitleImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+123+(1).png'
    featureMusio =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_2.webp'
    featureKimini =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_1.webp'
}
