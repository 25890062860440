
import { Vue, Component } from 'vue-property-decorator'
import ChoosePlan from '../components/ChoosePlan.vue'

@Component({
    components: {
        ChoosePlan,
    },
})
export default class Package extends Vue {
    product: { id: string; description: string } | null = null
    Category: string = 'Kodomo'

    created(): void {
        this.Category = this.package(this.product)
    }

    package(selectedPlan: any): string {
        if (selectedPlan.id.includes('ME')) {
            return 'Kodomo'
        } else if (selectedPlan.id.includes('adult')) {
            return 'Otona'
        } else {
            return 'Musio ESAT-J'
        }
    }

    call(product: any): void {
        this.product = product
    }
}
