
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class UserInterviewsAdult extends Vue {
    users = [
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/L1140231.png',
            position: 'center',
            text:
                'Musioとなら毎日続けられそうです。難しい内容もありますが、Musioが「Correct!」と言ってくれたときがとても嬉しくてどんどんチャレンジしたくなります。スキマ時間で英語マスター頑張ります。',
        },
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/L1140052.png',
            position: 'center',
            text:
                '教材もハワイに関しての情報がたくさん載っていて、ハワイ気分を味わいながらできるのがとても良いです。英語は苦手だけど、1ヶ月に４冊のペースなら頑張れそう！ハワイに行くのを目標に毎日Musioと話したいと思います。',
        },
    ]

    userInterviewTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/me_parent_voice_v2.png'
    signUpUrl =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/CTA02.png'
}
