
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class UserInterviews extends Vue {
    users = [
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/interview2.jpg',
            position: 'center',
            text:
                '勉強というよりも<b>楽しく遊ぶように学べるので</b>、子どもには心理的負担がなく、親には経済的負担が少なく、長く続けることができています。<b>Musioで予習のおかげで、オンライン英会話では先生と伸び伸び会話</b>をしています。',
        },
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/interview3.jpg',
            position: 'center',
            text:
                '<b>予習・復習のパートナーのMusio</b>は、子どもたちに愛される存在。子どもたちは学校の英語の授業に消極的でしたが、この学習サービスを始めて<b>英語好きになり、意欲的に英語を話そうとするように</b>なったのが一番の成長です。',
        },
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/interview1.jpg',
            position: 'right',
            text:
                '今まで動画や英語教室など様々な方法で英語を学ばせてきましたが、子どもが<b>喜んで英語を勉強している姿を見るのは初めて</b>です。保護者にとっては、家庭で子どもが英語を話している様子を見られるのでとても安心です。',
        },
    ]

    userInterviewTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/me_parent_voice_v2.png'
    signUpUrl =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/me_trial_v2.png'
}
