export function getOptionId(
    packageName: string,
    subscriptionTypeName: string,
    familyPackageContentsName: string,
    adultPackageContentsName: string,
    frequencies: string
): string {
    if (packageName === 'original') {
        if (subscriptionTypeName === 'monthly') {
            return 'ME_Plan_1'
        } else if (subscriptionTypeName === 'threeMonths') {
            return 'ME_Plan_2'
        } else {
            return 'ME_Plan_3'
        }
    } else if (packageName === 'kids') {
        return 'ME_Plan_3_1'
    } else if (packageName === 'adult') {
        if (adultPackageContentsName === 'adultPackageContentEdgeHawaii') {
            if (frequencies === 'fourLessonsPerMonth') {
                return 'adult_hawaii_4'
            } else if (frequencies === 'eightLessonsPerMonth') {
                return 'adult_hawaii_8'
            } else if (frequencies === 'thirtyLessonsPerMonth') {
                return 'adult_hawaii_30'
            } else {
                return 'adult_hawaii_60'
            }
        } else {
            if (frequencies === 'fourLessonsPerMonth') {
                return 'adult_business_4'
            } else if (frequencies === 'eightLessonsPerMonth') {
                return 'adult_business_8'
            } else if (frequencies === 'thirtyLessonsPerMonth') {
                return 'adult_business_30'
            } else {
                return 'adult_business_60'
            }
        }
    } else {
        if (familyPackageContentsName === 'familyPackageContentEdgeHawaii') {
            return 'ME_Plan_3_2_1'
        } else if (familyPackageContentsName === 'familyPackageContentEdgeBusiness') {
            return 'ME_Plan_3_2_2'
        } else {
            return 'ME_Plan_3_2_3'
        }
    }
}

export function getOptionIdEsatJ(
    packageName: string,
    subscriptionType: string,
    safetyPlan?: string
) {
    if (packageName === 'ipad') {
        if (safetyPlan === '加入する') {
            if (subscriptionType === 'threeMonths') {
                return 'ESAT-J_Plan_4'
            } else {
                return 'ESAT-J_Plan_6'
            }
        } else if (safetyPlan === '加入しない') {
            if (subscriptionType === 'threeMonths') {
                return 'ESAT-J_Plan_3'
            } else {
                return 'ESAT-J_Plan_5'
            }
        }
    } else if (packageName === 'standard') {
        if (subscriptionType === 'threeMonths') {
            return 'ESAT-J_Plan_1'
        } else {
            return 'ESAT-J_Plan_2'
        }
    }
}

export function getOptionName(optionId: string): string {
    if (optionId === 'ME_Plan_1' || optionId === 'ME_Plan_2' || optionId === 'ME_Plan_3') {
        return 'original'
    } else if (optionId === 'ME_Plan_3_1') {
        return 'kids'
    } else {
        return 'family'
    }
}

export function getProductId(frequency: string, packages: string, adultPackage: string): string {
    if (packages === 'adult' && adultPackage === 'adultPackageContentEdgeHawaii') {
        return '0ff89f85-0cb9-58b4-b23c-e7231d74c084'
    } else if (packages === 'adult' && adultPackage === 'adultPackageContentEdgeBusiness') {
        return 'b1f7a5b8-3515-5915-b041-4da742da1ca5'
    } else if (frequency === 'fourLessonsPerMonth') {
        return 'ea5f6b57-9cba-533d-871b-7937dce07529'
    } else if (frequency === 'eightLessonsPerMonth') {
        return '8d2f9134-945e-5bf5-9663-2fdff9a867e4'
    } else if (frequency === 'thirtyLessonsPerMonth') {
        return '13ea4faf-5872-593d-91b8-b4a3cbf5cac6'
    } else {
        return 'b6a58c28-77d4-5011-9a5b-41b182375cc0'
    }
}
export function getSubscriptionType(optionId: string): string {
    if (optionId === 'ME_Plan_1') {
        return 'monthly'
    } else if (optionId === 'ME_Plan_2') {
        return 'threeMonths'
    } else {
        return 'twelveMonths'
    }
}
export function getSubscriptionTypeNumber(optionId: string): number {
    if (optionId === 'ME_Plan_1') {
        return 1
    } else if (optionId === 'ME_Plan_2') {
        return 3
    } else {
        return 12
    }
}
export function getFrequency(productId: string): string {
    if (productId === 'ea5f6b57-9cba-533d-871b-7937dce07529') {
        return 'fourLessonsPerMonth'
    } else if (productId === '8d2f9134-945e-5bf5-9663-2fdff9a867e4') {
        return 'eightLessonsPerMonth'
    } else if (productId === '13ea4faf-5872-593d-91b8-b4a3cbf5cac6') {
        return 'thirtyLessonsPerMonth'
    } else {
        return 'sixtyLessonsPerMonth'
    }
}

export function getFamilyPackageContent(optionId: string): string {
    if (optionId === 'ME_Plan_3_2_1') {
        return 'familyPackageContentEdgeHawaii'
    } else if (optionId === 'ME_Plan_3_2_2') {
        return 'familyPackageContentEdgeBusiness'
    } else if (optionId === 'ME_Plan_3_2_3') {
        return 'familyPackageContentEdgeNhk'
    } else {
        return ''
    }
}

export function getPlanFormatToSaveFromUserdata(userdata: { [s: string]: any }): object {
    if (userdata == null || userdata.option == null) {
        return {}
    }
    const option = userdata.option
    let tempProduct: { [s: string]: any } = {}
    tempProduct.name = option.name
    tempProduct.price = option.price
    tempProduct.product_id = userdata.product_id
    tempProduct.packageName = getOptionName(option.id)
    tempProduct.subscriptionType = getSubscriptionType(option.id)
    tempProduct.frequency = getFrequency(userdata.product_id)
    tempProduct.familyPackageContent = getFamilyPackageContent(option.id)
    return tempProduct
}
