
import { Vue, Component } from 'vue-property-decorator'
import EsatJIcon from './EsatJIcon.vue'
import { EventBus } from '../plugins/eventbus'

@Component({
    components: {
        EsatJIcon,
    },
})
export default class EsatJAttack extends Vue {
    created() {
        EventBus.$on('scroll-to-element', (targetElementId: string) => {
            const element = document.getElementById(targetElementId)
            if (element) {
                element.scrollIntoView()
            }
        })
    }
}
