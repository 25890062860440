
import { Vue, Component } from 'vue-property-decorator'
import EsatJIcon from './EsatJIcon.vue'

@Component({
    components: {
        EsatJIcon,
    },
})
export default class MejRegistrantion extends Vue {
    userdata = this.$store.state.customer.userdata
    isJustOne = 0

    async created() {
        this.isJustOne = this.userdata.length
        console.log('데이터', this.userdata, '길이', this.isJustOne)
    }

    get planName() {
        if (this.isJustOne === 1 && !this.userdata[0].plan.includes('ESAT-J')) {
            return false
        } else if (this.isJustOne === 0) {
            return false
        } else if (this.isJustOne === 2) {
            return true
        } else {
            return true
        }
    }

    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
}
