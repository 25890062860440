
import { Vue, Component } from 'vue-property-decorator'

import MarketingPoints from '../components/MarketingPoints.vue'
import LessonContents from '../components/LessonContents.vue'
import FunctionList from '../components/FunctionList.vue'
import MediaCoverage from '../components/MediaCoverage.vue'
import KidsTabs from '../components/KidsTabs.vue'
import Instruction from '../components/Instruction.vue'

@Component({
    components: {
        MarketingPoints,
        LessonContents,
        FunctionList,
        KidsTabs,
        MediaCoverage,
        Instruction,
    },
})
export default class Kids extends Vue {
    colorCode = '#FF7575'
}
