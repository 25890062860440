
import { Vue, Component, PropSync } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'

@Component
export default class PasswordField extends Vue {
    @PropSync('password', { default: '' }) syncedPassword!: string

    isPassswordVisible = false
    passwordRules = [
        (v: string) => !!v || i18n.t('thisFieldIsRequired'),
        (v: string) => v.length >= 8 || i18n.t('minimumEightLetters'),
        (v: string) =>
            /^(?=.*[a-z])(?=[A-Z]*)(?=[0-9]*)[a-zA-Z0-9]{8,}$/.test(v) || i18n.t('invalidPassword'),
    ]

    getPlaceholder() {
        return this.$route.path.includes('/login')
            ? 'パスワード'
            : '新しいパスワードを入力してください'
    }
}
