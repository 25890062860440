
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Card } from '../store/modules/customer'

@Component
export default class CardInfo extends Vue {
    @Prop() card!: Card

    get cardLogo() {
        if (this.card.brand === 'Visa') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/visa.png'
        } else if (this.card.brand === 'MasterCard') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/mastercard.png'
        } else if (this.card.brand === 'Discover') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/discover.png'
        } else if (this.card.brand === 'JCB') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/jcb.png'
        } else if (this.card.brand === 'Diners Club') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/diners.png'
        } else if (this.card.brand === 'American Express') {
            return 'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/card_brand_images/amex.png'
        }
        return null
    }
}
