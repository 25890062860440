
import { Vue, Component, Watch } from 'vue-property-decorator'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import { ActionTypes } from './store/modules/authentication/types'
import { ActionTypes as CustomerActionTypes } from './store/modules/customer/types'
import * as Utils from '@/plugins/utils'

@Component({
    components: {
        Navbar,
        Footer,
    },
})
export default class App extends Vue {
    eventMessageSnackbar = false
    errorMessageSnackbar = false

    async created(): Promise<void> {
        if (this.$route.query.a8) {
            this.$store.commit(ActionTypes.SAVE_A_EIGHT_PARAM, { param: this.$route.query.a8 })
        }
        if (this.$store.state.authentication.token) {
            await this.$store.dispatch(CustomerActionTypes.GET_ORDER_HISTORY_REQUEST)
            await this.$store.dispatch(CustomerActionTypes.GET_USERDATA)
            const isAuthenticated = await this.$store.dispatch(CustomerActionTypes.GET_CARD_REQUEST)
            const isAdmin = Utils.adminCheck(this.$store.state.authentication.email)
            if (!isAdmin && !isAuthenticated) {
                this.$store.dispatch(ActionTypes.LOGOUT_REQUEST)
            }
        }
    }

    get theme(): string {
        return this.$vuetify.theme.dark ? 'dark' : 'light'
    }

    @Watch('$store.state.authentication.eventMessage')
    async onEventMessageReceived(val: string, oldVal: string) {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.eventMessageSnackbar = !!this.$store.state.authentication.eventMessage
        await this.delay(2000)
        this.$store.commit(ActionTypes.HIDE_EVENT_MESSAGE)
    }

    @Watch('$store.state.authentication.errorMessage')
    async onErrorMessageReceived(val: string, oldVal: string) {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.errorMessageSnackbar = !!this.$store.state.authentication.errorMessage
        await this.delay(2000)
        this.$store.commit(ActionTypes.HIDE_ERROR_MESSAGE)
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }
}
