
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

@Component
export default class ChoosePlanTab extends Vue {
    @Prop() title!: string
    @Prop() tabItems!: string[]
    @Prop({ default: { color: '#087426', background: '#E2FFDD' } }) selectedTabColor!: {
        background: string
        color: string
    }
    @Prop() tabValue!: string
    tabValueLocal: string = ''

    @Watch('tabValue', { immediate: true, deep: true })
    onTabValueChange(newValue: string, oldValue: string) {
        // 변경을 처리하고 로컬 데이터를 업데이트하거나 필요한 작업 수행
        this.tabValueLocal = newValue
    }
    @Emit('returnTabValue')
    watchTab() {
        return this.tabValueLocal
    }
    selectedTabStyle(tab: string, index: string): any {
        if (tab === index) {
            return {
                color: this.selectedTabColor.color,
                'background-color': this.selectedTabColor.background,
                'border-radius': '20px',
                // width: '99%',
                margin: '0 5px',
                padding: 0,
            }
        } else {
            return {
                // width: '99%',
                margin: '0 5px',
                padding: 0,
            }
        }
    }

    created(): void {
        this.tabValueLocal = this.tabValue
    }
}
