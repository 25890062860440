
import { Vue, Component } from 'vue-property-decorator'
import EsatJIcon from './EsatJIcon.vue'
import { EventBus } from '../plugins/eventbus'

@Component({
    components: {
        EsatJIcon,
    },
})
export default class EsatJPoint extends Vue {
    handleClick() {
        EventBus.$emit('scroll-to-element', 'target-element-id')
    }
    handleClick2() {
        EventBus.$emit('scroll-to-element', 'target-element-id2')
    }
    handleClick3() {
        EventBus.$emit('scroll-to-element', 'target-element-id3')
    }
}
