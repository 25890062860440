
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Timeline extends Vue {
    freeTrialTermTitleImageKids =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+123.png'
    freeTrialTermTitleImageAdult =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+125.png'
    timelineTitleImageKids =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+138.png'
    timelineTitleImageAdult =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+139.png'
    timelineImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/timeline7days.png'
    PreparingImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+141.png'
    get isKidsRoute() {
        return this.$route.params.tag === 'kids'
    }

    get isAdultRoute() {
        return this.$route.params.tag === 'adult'
    }
}
