
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class MyPageRenew extends Vue {
    data() {
        return {
            activePanel: 0,
            commonItems: ['1', '2'],
            kodomoItems: ['1', '2'],
            musioItems: ['1', '2'],
        }
    }
}
