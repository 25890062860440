
// @ts-nocheck
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/customer/types'

@Component
export default class RegistrationCompletedEsatJ extends Vue {
    $a8sales: any
    navigate(): void {
        this.$router.push('/login')
    }

    async created() {
        await this.$store.dispatch(ActionTypes.GET_USERDATA)

        const userLastData = this.$store.state.customer.userdata[
            this.$store.state.customer.userdata.length - 1
        ]
    }

    async mounted() {
        const a8salesSpan = document.createElement('span')
        a8salesSpan.id = 'a8sales'
        document.head.appendChild(a8salesSpan)

        await this.$store.dispatch(ActionTypes.GET_USERDATA)
        const userLastData = this.$store.state.customer.userdata[
            this.$store.state.customer.userdata.length - 1
        ]

        // This is where you can integrate the provided script
        const script1 = document.createElement('script')
        script1.src = '//statics.a8.net/a8sales/a8sales.js'
        document.head.appendChild(script1)

        const script2 = document.createElement('script')
        script2.textContent = `
        a8sales({
            "pid": "s00000024836002",
            "order_number": "${userLastData.stripe_id}",
            "currency": "JPY",
            "items": [
            {
                "code": "a8",
                "price": 1,
                "quantity": 1,
            },
            ],
            "total_price": 1
        });
        `
        document.head.appendChild(script2)
    }
}
