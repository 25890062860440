
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/customer/types'
import ChoosePlan from '@/components/ChoosePlan.vue'
import * as PlanUtils from '../plugins/planUtils'

@Component({
    components: {
        ChoosePlan,
    },
})
export default class MyPageSwitchPlan extends Vue {
    @Prop() readonly isEsatJ!: boolean
    product: {
        name: string
        price: number
        id: string
        product_id: string
        textbook: string
        price_change: number | undefined
    } | null = null
    showPopup = false

    userdata_product_id: string | null = null
    userdata_option_id: {
        name: string
        price: number
        id: string
        product_id: string
        textbook: string
    } | null = null
    userdata_plan: string | null = null
    buttonOn = false

    get package() {
        if (this.$store.state.customer.selectedProduct != null) {
            return this.$store.state.customer.selectedProduct.packageName
        } else {
            return 'original'
        }
    }

    set package(setPackage: string) {}
    async created(): Promise<void> {
        const isJustOne = this.$store.state.customer.userdata.length

        if (isJustOne === 1 && !this.$store.state.customer.userdata[0].plan.includes('ESAT-J')) {
            this.userdata_product_id = this.$store.state.customer.userdata[0].product_id
            this.userdata_option_id = this.$store.state.customer.userdata[0].option
            this.userdata_plan = this.$store.state.customer.userdata[0].plan
        } else if (isJustOne === 2) {
            if (!this.$store.state.customer.userdata[0].plan.includes('ESAT-J')) {
                this.userdata_product_id = this.$store.state.customer.userdata[0].product_id
                this.userdata_option_id = this.$store.state.customer.userdata[0].option
                this.userdata_plan = this.$store.state.customer.userdata[0].plan
            } else {
                this.userdata_product_id = this.$store.state.customer.userdata[1].product_id
                this.userdata_option_id = this.$store.state.customer.userdata[1].option
                this.userdata_plan = this.$store.state.customer.userdata[1].plan
            }
        }

        if (this.userdata_option_id && this.userdata_plan && this.userdata_product_id) {
            this.buttonOn = true
        }
        await this.$store.dispatch(ActionTypes.GET_PRODUCTS)
    }

    call(product: any): void {
        this.product = product
    }

    async onConfirmClicked(): Promise<void> {
        console.log('tttttt', this.product)

        if (this.product) {
            this.$store.commit(ActionTypes.START_PAYMENT)
            try {
                await this.$store.dispatch(ActionTypes.SWITCH_PLAN_REQUEST, {
                    productId: this.product.product_id,
                    option: {
                        id: this.product.id,
                        name: this.product.name,
                        price: this.product.price,
                        textbook: this.product.textbook,
                    },
                })
                await this.$store.dispatch(ActionTypes.GET_USERDATA)
                const tempProduct = PlanUtils.getPlanFormatToSaveFromUserdata(
                    this.$store.state.customer.userdata
                )
                this.$store.commit(ActionTypes.SAVE_OPTIONS, { product: tempProduct })
            } catch (e) {
                // TODO Error handling
                // eslint-disable-next-line no-console
                console.error(e)
            }

            this.showPopup = false
        }
    }
}
