
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FloatingSupportIcon extends Vue {
    @Prop() showCloseBtn!: boolean | true

    icon =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/floating_support_icon.png'
    isClosed = false

    hideIcon() {
        this.isClosed = true
    }
}
