
import { Vue, Component } from 'vue-property-decorator'
import RegistrationProgress from '../components/RegistrationProgress.vue'
import AddressSection from '../components/AddressSection.vue'
import PriceCards from '../components/PriceCards.vue'
import RegistrationPaymentCard from '../components/RegistrationPaymentCard.vue'
import RegistrationConfirmation from '../components/RegistrationConfirmation.vue'
import ChoosePlan from '../components/ChoosePlan.vue'
import { ActionTypes } from '../store/modules/customer/types'

@Component({
    components: {
        AddressSection,
        PriceCards,
        RegistrationProgress,
        RegistrationPaymentCard,
        RegistrationConfirmation,
        ChoosePlan,
    },
})
export default class Registration extends Vue {
    product = null
    Category: string = 'Kodomo'

    created(): void {
        this.$store.dispatch(ActionTypes.GET_PRODUCTS)
        // To delete card information(saved in local storage) at registration
        this.$store.commit(ActionTypes.GET_CARD_FAILURE)
        // 追加
        if (this.$store.state.customer.selectedProduct) {
            this.product = this.$store.state.customer.selectedProduct
            this.Category = this.getOption(this.product)
        }
    }

    getOption(selectedPlan: any): string {
        if (selectedPlan.id.includes('ME')) {
            return 'Kodomo'
        } else if (selectedPlan.id.includes('adult')) {
            return 'Otona'
        } else {
            return 'Musio ESAT-J'
        }
    }
    call(product: any): void {
        this.product = product
    }
}
