
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes as authentication } from '../store/modules/authentication/types'
import { ActionTypes as customer } from '../store/modules/customer/types'

@Component
export default class LogoutButton extends Vue {
    logout(): void {
        this.$store.dispatch(authentication.LOGOUT_REQUEST).then(() => {
            this.$store.commit(customer.STATE_RESET)
            this.$router.push('/')
        })
    }
}
