
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditAddressPopUp from './EditAddressPopUp.vue'
import AddressCard from './AddressCard.vue'
import { ActionTypes } from '../store/modules/customer/types'

@Component({
    components: {
        EditAddressPopUp,
        AddressCard,
    },
})
export default class AddressSection extends Vue {
    @Prop() disable: boolean | undefined
    addAddress = false

    created(): void {
        this.$store.dispatch(ActionTypes.GET_ADDRESSES)
    }
}
