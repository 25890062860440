
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PackageContentAdult extends Vue {
    cards = [
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardBg1.png',
            numImg:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardNum1.png',
            title: 'AIロボットMUSIOのレンタル',
            subtitle: '愛らしいロボットと一緒に学べる',
            content:
                'Musio EnglishのどのプランにもMusioのレンタルがついています。好きな時に好きなだけヒアリング・スピーキング・シャドーイングの練習ができます。慣れてきたらフリー会話にもチャレンジ！',
        },
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardBg2.png',
            numImg:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardNum2.png',
            title: '教材に沿ったオンライン英会話',
            subtitle: '学研講師による本格レッスン',
            content:
                'おとなのMusio Englishプランで使用する教材に沿ったコースを特別に提供しており、教材＋Musio＋オンライン英会話で反復学習を行うことができます。一回25分と社会人にも嬉しい短時間でのレッスンです。',
        },
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardBg3.png',
            numImg:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultCardNum3.png',
            title: '選べるオリジナル教材',
            subtitle: '目的に合わせて選択',
            content:
                'おとなのMusio Englishプランにはオリジナルの教材がついてきます。\n 学習の目的に合わせてお好きな教材をお選びください。',
        },
    ]

    packages = [
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultSectionHawaii.png',
            title: '1. Musio Hawaii (旅行英会話)',
            content:
                'Musioと一緒にHawaii旅行へ旅立ってみましょう！こちらの教材では主に旅行の際に使用する単語や表現を学ぶことができます。飛行機での会話から始まり、Hawaii現地でのショッピング、レストランでの食事、万が一の事態になった際の表現など、この一冊があれば海外旅行への出発から帰国まで完璧に準備ができます。\n そしてなんと英語だけでなく、ちょっと難しい入国税関申告書の書き方やハワイでの交通機関情報、おすすめ観光地など海外旅行・Hawaiiに関する情報がいっぱい。\n MusioとHawaiiへの、海外旅行への準備を進めてみませんか？',
        },
        {
            img:
                'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultSectionBusiness.png',
            title: '2. Musio Business (ビジネス英会話)',
            content:
                '海外でのビジネス、自信ありますか？ \n Businessの教材では、いわゆる「ビジネス英会話」を学ぶことができます。こちらはストーリーに沿って学ぶ内容であり、サンフランシスコでのビジネスをスタートさせるため、ビジネスパートナーを探すところから始まります。日本語でも日常会話とビジネス面で使用する言葉が異なるように、英語でも表現法などが大きく変わります。そして国が違えばビジネスの方法も異なる。52冊に渡って細かい「これなんて言えばいいんだろう」「アメリカではどうなんだろう」を解決してくれる教材です。今後のキャリアのため、会社のため、ご自身のため、Musioに英語でのビジネスを学んでみてはいかがですか？',
        },
    ]
}
