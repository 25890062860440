
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/authentication/types'
import i18n from '@/plugins/i18n'
import { CLIENT_KEY } from '@/utils/constants'

@Component
export default class FindPassword extends Vue {
    valid = false
    disableButton = false
    email = ''
    emailRules = [
        (v: string) => !!v || i18n.t('thisFieldIsRequired'),
        (v: string) => /.+@.+/.test(v) || i18n.t('invalidEmail'),
    ]

    async onClicked(): Promise<void> {
        this.disableButton = true
        await this.$store.dispatch(ActionTypes.FIND_PASSWORD_REQUEST, {
            email: this.email,
            key: CLIENT_KEY,
        })

        this.$router.push('/login')
    }
}
