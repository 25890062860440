
import { Vue, Component, Watch, PropSync } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/customer/types'
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus'
import { STRIPE_KEY } from '../utils/constants'

@Component({
    components: {
        CardNumber,
        CardExpiry,
        CardCvc,
    },
})
export default class EditCardPopUp extends Vue {
    @PropSync('open', { default: false }) isPopUpOpen!: boolean
    @PropSync('onGoing', { default: false }) registrationOnGoing!: boolean
    @PropSync('error', { default: false }) isError!: boolean

    holderName = ''
    isCompleted = false
    isNumberCompleted = false
    isExpiryCompleted = false
    isCvcCompleted = false
    isHolderNameCompleted = false

    stripeKey = STRIPE_KEY

    validateCard() {
        this.isCompleted =
            this.isNumberCompleted &&
            this.isExpiryCompleted &&
            this.isCvcCompleted &&
            this.isHolderNameCompleted

        if (this.isNumberCompleted) {
            if (!this.isExpiryCompleted) {
                const cardExpiryElement = this.$refs.cardExpiry as HTMLElement
                cardExpiryElement.focus()
            } else if (!this.isCvcCompleted) {
                const cardCvcElement = this.$refs.cardCvc as HTMLElement
                cardCvcElement.focus()
            }
        } else if (this.isExpiryCompleted) {
            if (!this.isCvcCompleted) {
                const cardCvcElement = this.$refs.cardCvc as HTMLElement
                cardCvcElement.focus()
            } else if (!this.isNumberCompleted) {
                const cardNumberElement = this.$refs.cardNumber as HTMLElement
                cardNumberElement.focus()
            }
        }
        // no focus magic for the CVC field as it gets complete with three
        // numbers, but can also have four
    }

    clearCardInfo() {
        const cardNumberElement = this.$refs.cardNumber as HTMLFormElement
        if (cardNumberElement != null) {
            cardNumberElement.clear()
        }
        const cardExpiryElement = this.$refs.cardExpiry as HTMLFormElement
        if (cardExpiryElement != null) {
            cardExpiryElement.clear()
        }
        const cardCvcElement = this.$refs.cardCvc as HTMLFormElement
        if (cardCvcElement != null) {
            cardCvcElement.clear()
        }
    }

    confirmCard() {
        if (this.isCompleted) {
            this.registrationOnGoing = true
            createToken({ name: this.holderName })
                .then(async (data: any) => {
                    const res = await this.$store.dispatch(ActionTypes.UPDATE_CARD_REQUEST, {
                        token: data.token.id,
                    })
                    if (!res) {
                        this.isError = true
                    }
                })
                .catch((error: any) => {
                    this.isError = true
                })
                .finally(() => {
                    this.clearCardInfo()
                })
        }
    }

    onCancelClicked(): void {
        this.clearCardInfo()
        this.isPopUpOpen = false
    }

    onSaveClicked(): void {
        this.confirmCard()
        this.isPopUpOpen = false
    }

    @Watch('isNumberCompleted')
    onNumberChanged() {
        this.validateCard()
    }

    @Watch('isExpiryCompleted')
    onExpiryChanged() {
        this.validateCard()
    }

    @Watch('isCvcCompleted')
    onCvcChanged() {
        this.validateCard()
    }

    @Watch('isHolderNameCompleted')
    onHolderNameChanged() {
        this.validateCard()
    }
}
