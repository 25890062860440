
import { Vue, Component } from 'vue-property-decorator'
import AdultEnglishPlan from '../components/AdultEnglishPlan.vue'
import ChooseBook from '../components/ChooseBook.vue'
import LessonContents from '../components/LessonContents.vue'
import UserInterviewsAdult from '../components/UserInterviewAdult.vue'
import Instruction from '../components/Instruction.vue'
import AdultMarketingPoints from '../components/AdultMarketingPoints.vue'

@Component({
    components: {
        AdultEnglishPlan,
        LessonContents,
        ChooseBook,
        UserInterviewsAdult,
        Instruction,
        AdultMarketingPoints,
    },
})
export default class Adult extends Vue {
    colorCode = '#53BEB7'
}
