
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EsatJChara extends Vue {
    cards = [
        {
            title: 'ESAT-Jに準拠した \n 模擬テストが100種以上',
            content:
                'ESAT-JのテストはPart AからPart Dの4つのまとまりで構成されています。\nMusio ESAT-J通信教育のアプリも実際の問題に準拠した模擬テストを準備\n問題内容も制限時間も、テストの進み方も全て実際のテストに準拠しています。',
        },
        {
            title: 'AIによる即時採点英語力の分析',
            content:
                '実際のテストの採点結果に基づき、AIが即時で模擬テストのスコアを算出。\n AIからのフィードバックもあるので、なぜそのスコアなのか、も確認可能。\n英語力も分析し、可視化することで学習のモチベーションをUPさせます。',
        },
        {
            title: 'ワークブックで予習復習も完璧',
            content:
                'ESAT-Jに準拠した問題をワークブックでも学習できます。\n ワークブックには重要なフレーズや、解き方のアドバイスも盛りだくさん。\n アプリとワークブックで徹底的に対策しましょう。',
        },
    ]
}
