
import { Vue, Component } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'
import { listNotification, createNotification, updateNotification } from '@/store/admin'

@Component({
    components: {},
})
export default class Notification extends Vue {
    expanded = []
    selectedRow = []
    headers = [
        { text: 'id', value: 'id' },
        { text: 'タイトル', value: 'title' },
        { text: '表示', value: 'is_visible' },
        { text: 'メール転送必要', value: 'need_send_mail' },
        { text: 'メール転送', value: 'send_mail_action', sortable: false },
        { text: 'メール転送日付', value: 'mail_sent_info' },
        { text: '作成日付', value: 'created_at' },
        { text: '編集日付', value: 'updated_at' },
        { text: '本文', value: 'data-table-expand' },
    ].map(item => {
        const obj: any = item
        obj.align = 'center'
        return obj
    })
    notifications = []

    // necessary data for sending server
    form = {
        id: '',
        title: '',
        description: '',
        is_visible: '',
        need_send_mail: '',
    }

    alreadySentMail = false

    isvisibleSelectable = [
        {
            text: '非表示',
            value: 'False',
        },
        {
            text: '表示',
            value: 'True',
        },
    ]

    sendMailSelectable = [
        {
            text: '転送しない',
            value: 'False',
        },
        {
            text: '転送する',
            value: 'True',
        },
    ]

    inputMode = ['新規作成', '編集']
    selectedMode = ''

    rules = {
        required: (v: string) => !!v || i18n.t('thisFieldIsRequired'),
    }

    async created(): Promise<void> {
        const lists = await listNotification(this.$store.state.authentication.token)
        this.setDisplayItems(lists)
    }

    async submit(): Promise<void> {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            if (this.form.id == '') {
                const lists = await createNotification(
                    this.$store.state.authentication.token,
                    this.form
                )
                this.setDisplayItems(lists)
            } else {
                const lists = await updateNotification(
                    this.$store.state.authentication.token,
                    this.form
                )
                this.setDisplayItems(lists)
            }
        }
    }

    // modify format of data to show notification list table
    setDisplayItems(lists: any): void {
        this.notifications = lists.map((item: any) => {
            const notification = Object.assign({}, item)
            notification.is_visible = item.is_visible ? '表示' : '非表示'
            notification.need_send_mail = item.need_send_mail ? '必要' : '不要'
            notification.mail_sent_info = item.is_mail_sent
                ? this.convertDatetime(item.mail_sent_date)
                : ''
            notification.created_at = this.convertDatetime(item.created_at)
            notification.updated_at = this.convertDatetime(item.updated_at)
            notification.send_mail_action =
                item.need_send_mail && item.mail_sent_date == null ? true : false
            return notification
        })
    }

    convertDatetime(date: string): string {
        return new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString()
    }

    // when click the 「新規作成」button
    clickNewBtn(): void {
        ;(this.$refs['form'] as HTMLFormElement).reset()
        this.form.id = ''
        this.selectedMode = this.inputMode[0]
        this.alreadySentMail = false
    }

    // when click the 「編集」button
    clickEditBtn(): void {
        if (this.selectedRow.length == 0) {
            alert('編集するデータを選択してください。')
            return
        }
        const row = this.selectedRow[0]
        this.form.id = row['id']
        this.form.title = row['title']
        this.form.description = row['description']
        this.form.is_visible = row['is_visible'] === '表示' ? 'True' : 'False'
        this.form.need_send_mail = row['need_send_mail'] === '必要' ? 'True' : 'False'

        this.alreadySentMail = row['mail_sent_date'] == null ? false : true

        this.selectedMode = this.inputMode[1]
    }

    sendMail(item: any): void {
        alert('まだ未実装です。')
    }
}
