
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditAddressPopUp from './EditAddressPopUp.vue'
import { ActionTypes } from '../store/modules/customer/types'

@Component({
    components: {
        EditAddressPopUp,
    },
})
export default class AddressCard extends Vue {
    @Prop() addressInfo: any
    editAddress = false

    onAddressSelected(addressId: string): void {
        this.$store.dispatch(ActionTypes.SELECT_ADDRESS, { id: addressId })
    }

    onDeleteClicked(addressId: string): void {
        this.$store.dispatch(ActionTypes.DELETE_ADDRESS, { id: addressId })
    }
}
