import { render, staticRenderFns } from "./Plantable.vue?vue&type=template&id=c157f816&scoped=true"
import script from "./Plantable.vue?vue&type=script&lang=ts"
export * from "./Plantable.vue?vue&type=script&lang=ts"
import style0 from "./Plantable.vue?vue&type=style&index=0&id=c157f816&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c157f816",
  null
  
)

export default component.exports