
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class CancellationSurvey extends Vue {
    googleFormUrl =
        '//docs.google.com/forms/d/e/1FAIpQLSeCj42xdZJDCHu65uoyZryjj94ptT2cZeDVmfSYNyJNS6Skyw/viewform'
    campaignForm = 'https://forms.gle/GJ5Zuqi3HfyBLN9j9'
    formHeight = 2600
    formWidth = 1000

    mounted(): void {
        if (window.innerWidth <= 280) {
            this.formHeight = 2300
            this.formWidth = 280
        } else if (window.innerWidth < 768) {
            this.formHeight = 2300
            this.formWidth = 330
        } else if (window.innerWidth <= 1024) {
            this.formHeight = 2100
            this.formWidth = 750
        }
    }
}
