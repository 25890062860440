
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes as CustomerActionTypes } from '@/store/modules/customer/types'

@Component({
    components: {},
})
export default class SendMail extends Vue {
    selectList = ''
    selectDuration = ''
    selectNumber = ''
    sendList = ['forAll', 'forDate', 'forDuration', 'forNumber', 'forCustom']
    sendDurationList = ['1', '3', '6', 'over']
    sendNumberList = ['4', '8', '30', '60']
    created(): void {}
    dates = []
    dateRangeText = ''
    mailHtml = ''
    mailTitle = ''
    mailTest = ''
    eventCheck = false

    dateRangeFunction() {
        this.dateRangeText = this.dates.join(' ~ ')
    }
    async submitForDate(): Promise<void> {
        const form = {
            dateRangeText: this.dateRangeText,
            mailHtml: this.mailHtml,
            selectList: this.selectList,
            mailTitle: this.mailTitle,
            mailTest: this.mailTest,
            selectDuration: this.selectDuration,
            selectNumber: this.selectNumber,
            eventCheck: this.eventCheck,
        }
        try {
            const res = await this.$store.dispatch(CustomerActionTypes.SEND_ADMIN_MAIL, {
                data: form,
            })
            // eslint-disable-next-line no-console
            console.log(res)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
        }
    }
}
