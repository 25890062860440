
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PackageContentKidsFamily extends Vue {
    @Prop({ default: '#BFD2FF' }) selectedColor?: string

    packageKidsUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_kids_web_new.png'
    packageSweetKitchenWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_sweet _kitchen.png'
    packageAmazingDinosaursWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_amazing_dinosaurs.png'
    packageInterestingTownWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_interestingtown.jpg'
    packageFlashcardNumbersWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_flashcard_numbers.png'
    packageFlashcardAbcsWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_flashcard_abcs.png'
    packageFlashcardDinosaursWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_flashcard_dinosaurs.jpg'
    packageFlashcardInstrumentWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_flashcard_instrument.jpg'
    packageBremenCover1Web =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_bremen_cover1.jpg'
    packageOldstoriesWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_oldstories.jpg'
    contentsKidsFamilySophyTitleImage =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/sophy_title%402x.png'
    packageBremenImage =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/bremen.webp'
    packageOldstoriesImage =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/oldstories.webp'

    selectedSubtitleFormatStyle(rowNum: number): any {
        let transparentPercentage: string = '40%'
        if (rowNum === 2) {
            transparentPercentage = '20%'
        }
        return {
            background:
                'linear-gradient(transparent ' +
                transparentPercentage +
                ', ' +
                this.selectedColor +
                ' 0%)',
            'font-weight': 'bold',
            'font-size': '25px',
            'text-align': 'center',
        }
    }
}
