export const lists = [
    {
        question: 'マスクはどこで製造されていますか。',
        answer:
            'FDA(アメリカ医療機器認証)およびCEマーク(ヨーロッパ連合基準適合認証)の取得実績のある中国の工場にて安全に製造されています。',
    },
    {
        question: 'マスクはいつ発送されますか。',
        answer: '入金確認後発送いたします。',
    },
    {
        question: 'AKAの営業所では販売しないのですか。',
        answer: '本キャンペーンページ以外での販売予定はございません。',
    },
    {
        question: 'WEBを利用していないのですが、電話応募できますか。',
        answer: '本キャンペーンからのご購入に限らせていただいております。',
    },
    {
        question: 'マスクの仕様について教えてください。',
        answer:
            '3層タイプの不織布マスクで色は白です。サイズはこども用のマスク(約95mm×約145mm)のみとなります。',
    },
    {
        question: 'マスクの素材について教えてください。',
        answer: '主要素材は、ポリプロピレンとメルトブローンとなります。',
    },
    {
        question: '一箱何枚入りですか。',
        answer: '一箱50枚入りで販売します。',
    },
    {
        question: '価格はいくらになりますか。',
        answer: '50枚入り税抜き1500円(送料無料)です。',
    },
    {
        question: '一人あたりの購入数上限はありますか。',
        answer: '1世帯1箱（50枚入り）限りとさせていただきます。',
    },
    {
        question: 'どんな支払い方法が使えますか。',
        answer:
            '今回のキャンペーンでの販売では、クレジットカードによるお支払いでのご購入に限らせていただいております。',
    },
    {
        question: '海外への発送には対応していますか。',
        answer: '日本国内向けのため、海外への発送は承っておりません。',
    },
]
