
import { Vue, Component } from 'vue-property-decorator'
import PriceCards from '../components/PriceCards.vue'

@Component({
    components: {
        PriceCards,
    },
})
export default class PriceOverview extends Vue {
    priceTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/price-overview-title.png'
    pricePic1 =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/price-overview-pic1.png'
    pricePic2 =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/price-overview-pic2.png'
    pricePic3 =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/price-overview-pic3.png'
    pcPricingUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/pc_pricing_total_page.jpg'
    pc14DaysFreeUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/pc_14days_free_banner.png'
    mobile14DaysFreeUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/mobile_14days_free_banner.png'
}
