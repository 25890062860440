import { render, staticRenderFns } from "./MejRegistrantion.vue?vue&type=template&id=58188758&scoped=true"
import script from "./MejRegistrantion.vue?vue&type=script&lang=ts"
export * from "./MejRegistrantion.vue?vue&type=script&lang=ts"
import style0 from "./MejRegistrantion.vue?vue&type=style&index=0&id=58188758&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58188758",
  null
  
)

export default component.exports