
import { Vue, Component } from 'vue-property-decorator'
import EsatJIcon from './EsatJIcon.vue'

@Component({
    components: {
        EsatJIcon,
    },
})
export default class EsatJFree extends Vue {
    goTo(url: string): void {
        window.open(url, '_blank')
    }
    openGooglePlayLink() {
        window.open('https://play.google.com/store/apps/details?id=kr.akaai.esat_android', '_blank')
    }
    openAppleStoreLink() {
        window.open('https://apps.apple.com/jp/app/musio-esat-j/id6446240178', '_blank')
    }
}
