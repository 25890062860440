import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as authentication from './modules/authentication'
import * as customer from './modules/customer'

Vue.use(Vuex)

export interface RootState {
    authentication: authentication.AuthenticationState
    customer: customer.CustomerState
}

export default new Vuex.Store<RootState>({
    modules: {
        authentication: authentication.store,
        customer: customer.store,
    },
    plugins: [createPersistedState()],
    strict: process.env.NODE_ENV !== 'production',
})
