
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/customer/types'

@Component
export default class RegistrationConfirmation extends Vue {
    @Prop() readonly product: any | undefined
    esatJPlan: boolean = false

    async onPaymentConfirmed(): Promise<void> {
        this.$store.commit(ActionTypes.START_PAYMENT)

        if (this.product.id.includes('ESAT-J')) {
            this.esatJPlan = true
        } else {
            this.esatJPlan = false
        }

        const res = await this.$store.dispatch(ActionTypes.REGISTRATION_REQUEST, {
            // TODO 確認
            productId: this.product?.product_id,
            option: {
                id: this.product?.id,
                name: this.product?.name,
                price: this.product?.price,
                textbook: this.product?.textbook,
                option_price: this.product?.option_price,
            },
            userIdentifier: this.$store.state.authentication.a8,
            isESATJ: this.esatJPlan,
        })
        if (res) {
            if (this.product.id.includes('ESAT-J')) {
                this.$router.push('/signup-completed/musio_esatj'), this.executeTracking()
            } else {
                this.$router.push('/signup-completed'), this.executeTracking()
            }
        } else {
            // TODO error handring
        }
        console.log('프로덕트', this.product, this.esatJPlan)
    }

    numberWithCommas(x: number): string {
        const parts = x.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    }

    get price(): number {
        return this.product?.price || 0
    }

    get tax(): number {
        return this.price - Math.round(this.price * (1 / 1.1))
    }

    get priceWihtouTax(): number {
        return Math.round(this.price * (1 / 1.1))
    }

    get optionPrice(): number {
        return this.product?.option_price || 0
    }

    executeTracking(): void {
        const PV: string = 'phdecmfk44er'
        const KEYS: Record<string, string[]> = {
            cid: ['CL_', 'ACT_', 'cid_auth_get_type'],
            plid: ['PL_', 'APT_', 'plid_auth_get_type'],
        }
        let turl: string = 'https://be-star.jp/track.php?p=' + PV
        const cks: Record<string, string> = document.cookie
            .split('; ')
            .reduce((ret: Record<string, string>, s: string) => {
                const kv: string[] = s.split('=')
                if (kv[0] && kv[1]) {
                    ret[kv[0]] = kv[1]
                }
                return ret
            }, {})
        turl = Object.keys(KEYS).reduce((url: string, k: string) => {
            const vk: string = KEYS[k][0] + PV
            const tk: string = KEYS[k][1] + PV
            let v: string = ''
            let t: string = ''
            if (cks[vk]) {
                v = cks[vk]
                if (cks[tk]) t = cks[tk]
            } else if (localStorage.getItem(vk)) {
                v = localStorage.getItem(vk) || ''
                t = 'ls'
            }
            if (v) url += '&' + k + '=' + v
            if (t) url += '&' + KEYS[k][2] + '=' + t
            return url
        }, turl)
        const xhr: XMLHttpRequest = new XMLHttpRequest()
        xhr.open('GET', turl)
        xhr.send()
        console.log('done')
    }
}
