import Vue from 'vue'
import VueRouter from 'vue-router'
import Campaign from '../pages/Campaign.vue'
import CampaignLanding from '../pages/CampaignLanding.vue'
import Landing from '../pages/Landing.vue'
import Login from '../pages/Login.vue'
import MyPage from '../pages/MyPage.vue'
import Registration from '../pages/Registration.vue'
import RegistrationCompleted from '../pages/RegistrationCompleted.vue'
import Signup from '../pages/Signup.vue'
import TimeLineTab from '../pages/TimeLineTab.vue'
import FindPassword from '../pages/FindPassword.vue'
import store from '../store'
import Package from '@/pages/Package.vue'
import CancellationSurvey from '@/pages/CancellationSurvey.vue'
import SupportForm from '@/pages/support/SupportForm.vue'
import SupportConfirm from '@/pages/support/SupportConfirm.vue'
import Admin from '@/pages/Admin.vue'
import Error from '../pages/Error.vue'
import Plantable from '../pages/Plantable.vue'
import * as Utils from '@/plugins/utils'
import RegistrationCompletedEsatJ from '../pages/RegistrationCompletedEsatJ.vue'
import MyPageRenew from '../pages/MyPageRenew.vue'
import FeedbackEsatJ from '../pages/FeedbackEsatJ.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: Landing },
        { path: '/kids', component: Landing },
        { path: '/adult', component: Landing },
        { path: '/musio_esatj', component: Landing },
        {
            path: '/admin',
            name: 'Admin',
            component: Admin,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/admin/:session',
            name: 'AdminContent',
            component: Admin,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/login',
            component: Login,
        },
        {
            path: '/plan/:package',
            name: 'plan_package',
            component: Package,
            props: true,
        },
        {
            path: '/plan',
            name: 'plan',
            component: Package,
        },
        {
            path: '/signup',
            component: Signup,
        },
        {
            path: '/find-password',
            component: FindPassword,
        },
        {
            path: '/signup/kakaku',
            component: Signup,
        },
        {
            path: '/registration',
            component: Registration,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/registration/:package',
            name: 'registration_package',
            component: Registration,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/mypage',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/mypagere',
            component: MyPageRenew,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/user-info-edit',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/change-password',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/feedback/esatj',
            component: FeedbackEsatJ,
        },
        {
            path: '/shipping-address',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/payment-card',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/registration-mej',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/switch-plan',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/cancellation',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/referral',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/mej-user-info',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/switch-plan',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/registration-me',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/cancellation-mej',
            component: MyPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/signup-completed',
            component: RegistrationCompleted,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/signup-completed/musio_esatj',
            component: RegistrationCompletedEsatJ,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/campaign',
            component: Campaign,
        },
        {
            path: '/campaign-landing',
            component: CampaignLanding,
        },
        {
            path: '/feedback',
            component: CancellationSurvey,
        },
        {
            path: '/timeline/:tag',
            component: TimeLineTab,
        },
        {
            path: '/support',
            component: SupportForm,
        },
        {
            path: '/plantable',
            component: Plantable,
        },
        {
            path: '/support_confirm',
            component: SupportConfirm,
        },
        {
            path: '/error',
            component: Error,
        },
    ],
    // ページ移動し、スクロールをtopに動かす
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some(route => route.meta.requiresAuth)
    const { isAuthenticated } = store.getters
    const isAlreadyMember = store.state.customer.isSignedUp
    const is_admin = Utils.adminCheck(store.state.authentication.email)

    if (is_admin) {
        next()
    } else {
        if (authRequired && !isAuthenticated) {
            next({ path: '/login', query: { redirect: to.fullPath } })
        } else if (
            authRequired &&
            isAuthenticated &&
            !isAlreadyMember &&
            !['/registration', '/signup-completed', '/signup-completed/musio_esatj'].includes(
                to.path
            )
        ) {
            next({ path: '/registration' })
        } else if (isAuthenticated && to.path === '/') {
            next({ path: '/mypage' })
        } else if (
            to.path.includes('admin') &&
            !Utils.adminCheck(store.state.authentication.email)
        ) {
            next({ path: '/' })
        } else {
            next()
        }
    }
})

export default router
