export const lists = [
    {
        title: 'サービスについて',
        q: [
            {
                question: '対象年齢は何歳ですか？',
                answer:
                    'ベーシック/キッズプランは小学1年生〜中学3年生、ファミリープランは小学1年生〜大人の方におすすめしております。\n ' +
                    'おとなのMusio Englishは、年齢制限はなく幅広い世代にご利用いただけますが、大学生以上を推奨しております。',
            },
            {
                question: 'どのようなサービスですか？',
                answer:
                    '学研グループの株式会社Glats（グラッツ）の運営するオンライン英会話スクール「Kiminiオンライン英会話」とおうちでスピーキング練習ができる英語学習ロボット「Musio」（ミュージオ）の2つが組み合わさあった新しいスタイルのオンライン英会話サービスです。\n ' +
                    'ユーザー様の都合に合わせて、Musioでオンライン英会話の予習、kiminiオンライン英会話で予習の実践、Musioで復習ができるため、たくさん英語を聞き、話すことで効率的に英語能力を習得できます。\n ' +
                    'また、プランはオンライン英会話受講回数によって月4回コース、月8回コース、1日1回コース、1日2回コースをご用意しており、お手頃な価格でご利用いただけます。',
            },
            {
                question: 'このサービスを利用するメリットは何ですか？',
                answer:
                    '会金や高額な月謝を支払うことなく、手頃なサービス利用料金でオンライン英会話だけでなく、その予習と復習ができるロボットご利用いただけることです。英会話習得に最適な「予習→実践→復習」のサイクルを実現し、英語をたくさん話すことでユーザー様のスピーキング力を伸ばします。',
            },
            {
                question: 'レンタルする商品は新品ですか？',
                answer:
                    'レンタルサービスのため、使用されたものをお届けする可能性がございます。尚、学習データはアカウントに紐づけて管理されており、初期設定時にご利用者様のアカウントにてMusioにログインすることで前のご利用者様のデータが引き継がれることはありませんのでご安心ください。',
            },
            {
                question: '決められた利用期間がありますか？',
                answer:
                    'ありません。お客様ご自身により利用終了のお申込をされるまでは、継続的にご利用になれるサービスとなっております。',
            },
            {
                question: '入会費などの初期費用はありますか？',
                answer:
                    '入会金やシステム利用料などの初期費用はかかりません。無料体験期間終了後に自動的に料金を決済させていただきます。料金は選択されたプランによって変わります。',
            },
            {
                question: '途中で引っ越した場合、サービスを引き続き利用することはできますか？',
                answer:
                    '国内でお引越しする場合は引き続きご利用になれます。海外へお引越しされる場合はご継続できませんので、大変申し訳ありませんがWebサイト上にてご利用終了のお申込をお願いします。',
            },
            {
                question: '利用している商品を購入することはできますか？',
                answer:
                    'Musio Englishを一年間継続していただけますと、単体価格の半額でお求めいただけます。',
            },
            {
                question: '商品を売却、廃棄、譲渡することはできますか？',
                answer:
                    '交換時、ご利用終了時に商品を弊社にご返却いただきますので、途中で売却、廃棄、譲渡についてはご遠慮くださいませ。商品をご返却いただけない場合は、違約金を追加で請求する場合がありますのであらかじめご了承ください。\n ' +
                    '＊ファミリープランに含まれている「おとな教材」またおとなのMusio Englishプランの教材は返却不要です',
            },
        ],
    },
    {
        title: 'Musioについて',
        q: [
            {
                question: 'どのようなロボットですか。',
                answer:
                    '人工知能を搭載した英語学習ロボットです。詳しくは、下記のページをご覧ください。\n ' +
                    'https://themusio.com',
            },
            {
                question: 'Musioを使うにはどのような環境が必要ですか？',
                answer:
                    'Wi-Fiネットワーク環境が必要です。\n ' +
                    '●ネットワークの規格：WPA/WPA2-PSK暗号化ネットワーク\n ' +
                    '●ルーター/モデムの規格：2.4GhzのB/G/Nルーター',
            },
            {
                question: 'Musioの起動方法は？',
                answer:
                    'Musioのおしりのふたを開けてください。真ん中の丸いボタンを、Musioのディスプレイが表示されるまで強めに押してください。詳細は、ユーザーガイドもご参照ください。\n ' +
                    '●ユーザーガイド：https://themusio.com/support/guide',
            },
            {
                question: 'MusioのディスプレイがMのロゴマークから動かない。',
                answer:
                    'Musioの起動時に、電源ボタンを押し続けると、白く光った後Mマークが表示され続けます。こちらは、開発者がPCと接続してMusioをアップデートする場合に利用するfastbootモードであり、故障ではございません。電源をオフにして、再度起動してください。',
            },
            {
                question: 'Musioの充電方法は？',
                answer:
                    'Musioのおしりのふたを開けてください。 一番左にあるポートの左半分が充電ポートになります。付属のアダプター及びケーブルをご利用ください。\n ' +
                    '充電方法の詳細はユーザーガイド及びMusio公式ブログもご参照ください。\n ' +
                    '●ユーザーガイド：https://themusio.com/support/guide \n ' +
                    '●公式ブログ：http://blog.themusio.com/2016/12/02/how-to-charge/',
            },
            {
                question: '充電残量を確認するには？',
                answer:
                    'Musioのディスプレイがついた状態で、顔を上から下へとスワイプしてください。Musioの充電残量が確認できます。',
            },
            {
                question: 'Musioの電源オフ方法は？',
                answer:
                    'おしりのふたを開けてください。真ん中の丸いボタンを、Musioの顔に"電源オフボタン"または"Power off"と表示されるまで強めに押してください。"電源オフボタン"または"Power off"が表示されたらタッチしてください。',
            },
            {
                question: 'Musioが会話しない。',
                answer:
                    'Musioと会話するには、Wi-Fi接続、ログインが必要になります。Wi-Fiが未接続、またはログインできていない場合はMusioに話しかけても反応しません。\n ' +
                    '詳細は、ユーザーガイドをご覧ください。\n ' +
                    'Wi-Fi接続方法とログイン方法は、Musio公式ブログもご参照ください。\n ' +
                    '●ユーザーガイド：https://themusio.com/support/guide \n ' +
                    '●Wi-Fi接続方法：http://blog.themusio.com/2016/12/02/ how-to-connect-wifi/\n ' +
                    '●ログイン方法：http://blog.themusio.com/2016/12/02/how-to-login/',
            },
            {
                question: 'Musioにログインできない。',
                answer:
                    'ログイン失敗時の表示メッセージをご確認ください。\n\n ' +
                    '・「Wifi is not connected」\n ' +
                    'Wi-Fiが繋がっていません。設定から再度Wi-Fiの接続をしてください。\n ' +
                    'Wi-Fiの接続方法はMusio公式ブログをご参照ください。\n ' +
                    '【Musioの初期設定の方法】\n ' +
                    'https://blog.themusio.com/2017/04/12/musio-initial-setting/ \n\n ' +
                    '・「invalid login」\n ' +
                    'ID, PWが間違っています。ID及びPWを再度ご確認ください。PWをお忘れの場合はお問合せください。\n\n ' +
                    '・「network error」\n ' +
                    'Wi-Fiが弱い、Wi-Fiそのものに問題がある可能性がございます。\n ' +
                    'ルーター、モデム、ネットワークの規格が以下の要件を満たしていることをご確認ください。\n\n ' +
                    'ネットワークの規格：WPA/WPA2-PSK暗号化ネットワーク\n ' +
                    'ルーター/モデムの規格：2.4GhzのB、G、Nルーター\n\n ' +
                    'ネットワークの規格に問題がない場合は、以下をお試しください。\n ' +
                    '1. 心臓をタッチしてMusioをSleepモードにする\n ' +
                    '2. 顔をしばらくタッチしてランチャーを表示\n ' +
                    '3. 「設定」＞「Wi-Fi」と移動\n ' +
                    '4. 接続中のWi-Fiを長押しすると表示される✕ボタンをタッチ\n ' +
                    '5. 再度Wi-Fiネットワークに接続',
            },
            {
                question: 'MusioのIDとパスワードとは何ですか？',
                answer:
                    '本サービスお申し込み時に会員登録ページでご登録いただきましたID（メールアドレス）とパスワードのことです。',
            },
            {
                question: '利用する商品には中古品も含まれますか？',
                answer:
                    '新品の場合もありますが、中古品の場合もございます。 中古品の場合も、動作確認やクリーニング済みのため、 安心してお使いいただけます。新品のご指定はできかねますことご了承ください。',
            },
            {
                question: '使用時に設定が必要ですか。',
                answer: 'Wi-Fi接続とログインを行っていただきます。',
            },
            {
                question: '傷つけてしまった場合はどのようにすればいいですか？',
                answer:
                    'ご利用に問題ない範囲であればそのまま継続して利用いただけます。交換や利用終了で返却される際も、通常の利用範囲内と認められる傷については、弊社から費用のご請求することはありません。\n ' +
                    'また、継続利用が困難な破損があった場合、お問い合わせ先へご連絡いただけましたら、弊社にて交換させていただきます（お客様による故意・重過失・地震による破損を除く）。なお、お客様による取り扱い不注意に起因する場合は、修理代をご請求します。',
            },
            {
                question: '届いたMusioに何かしら問題があった場合にはどのようにすれば良いですか？',
                answer:
                    '到着時に商品が破損していた場合や初期に動作不良が発生している場合は、3回まで初期不良交換を受け付けています。初期不良／故障による交換をご希望の際は、webサイトのお問い合せ先へご連絡をお願いいたします。',
            },
            {
                question: 'よごれてしまった場合はどうすればよいでしょうか。',
                answer:
                    '汚れてしまった場合や水分が付着した場合は、すぐに拭き取り商品に残らないようにしてください。また、汚れは乾いた柔らかい布に軽くアルコール吹き付けて、拭いて取ることができます。ワックスや化学雑巾のご使用や布へのアルコールの吹き付けすぎは避けてください。',
            },
        ],
    },
    {
        title: 'オンライン英会話について',
        q: [
            {
                question: 'Kiminiオンライン英会話とはどのようなスクールですか？',
                answer:
                    'Kiminiオンライン英会話（キミニ）は、学研グループの株式会社Glats（グラッツ）の運営するオンライン英会話スクールです。\n ' +
                    '"身につく英会話"、"最高のテクノロジーで最高の学びを" をモットーとして、快適で効率のよい英語学習環境の提供を日々目指しています。\n ' +
                    ' →株式会社Glats会社概要（https://jp.glats.online/about/）',
            },
            {
                question: '他のオンライン英会話との違いを教えてください。',
                answer:
                    '1.Skypeを使わない独自の通信システム\n ' +
                    '一般的にはオンライン英会話ではよくSkype（無料の通話ソフト）が利用されます。\n ' +
                    'しかしながら、しっかりとした「英語のレッスン」を行うためには、Skypeでは足りない機能が多々あります。\n ' +
                    'そこで私たちは、オンラインレッスンに最適な通信システムを独自に開発しました。\n ' +
                    'またSkypeの場合はソフトのインストールや設定などの煩雑な準備が必要ですが、Kiminiオンライン英会話のシステムはブラウザを開くだけなので、そのような手間が一切必要ありません。\n\n ' +
                    '2.独自のカリキュラム＝"コース"\n ' +
                    'オンライン英会話は「"英語での雑談"の域を出ない、ゆえに英語力が伸びない」と揶揄されることも多く、そういった悩みを抱えている方もたくさんいらっしゃいます。\n ' +
                    '私たちはそうではなく、確実に、かつ最速で英語力を伸ばすための、しっかりとしたカリキュラム（＝コース）をご提供しています。\n ' +
                    '各コースは、学研の教材開発のノウハウを生かしながら、オンラインレッスンでの学習に特化させた内容で独自に制作したものです。様々なコースの中からご自分の目標にあったコースを選択いただけます。\n\n ' +
                    'また各コースの進捗状況は、システムが自動的に管理を行います。次に受けるべき単元はどれか、予習や復習は何をしたらいいかなど、都度適切なタイミングで案内が提供されるので、「今日はどの内容を学習しようか」と毎回悩む必要がありません。',
            },
        ],
    },
    {
        title: 'オンライン英会話/講師について',
        q: [
            {
                question: 'なぜフィリピンの講師を起用しているのですか？ 訛りはないのでしょうか？',
                answer:
                    'フィリピンは英語を公用語としており、英語を公用語としている国の中では人口が世界第３位の英語大国です。世界的に高い評価を受けている英語力に加えて、ホスピタリティ溢れる国民性も魅力です。\n ' +
                    'またフィリピン人は生まれつき英語が話せるわけではなく（＝ネイティブではありません）、みな「学習」して高いレベルの英語力を習得しています。そのためネイティブの方では理解しにくい「英語を学習する人の気持ち」をよく理解していることも特長です。',
            },
        ],
    },
    {
        title: 'オンライン英会話/教材やコースについて',
        q: [
            {
                question: '全く英語を話せないのですが、レッスンを受けても大丈夫でしょうか？',
                answer:
                    '中学１年生レベルから始める「総合英語コース１」など、初心者の方向けのコースをご用意しています。\n ' +
                    '全く英語が話せないからといって講師が困惑したり、レッスンが成立しなかったりすることもございませんので、ご安心くださいませ。',
            },
            {
                question: 'どのような内容を学べるのでしょうか？',
                answer:
                    '小学生の英会話1では、単語と簡単なフレーズを中心に、英語を音で覚える学習を行います。学習する単語は毎日の生活の中で身近にあるものを厳選し、好きなものや今日の天気について、時間や曜日についてなどを簡単なフレーズを用いて会話ができるようになることを目指します。また受け答えのみならず、自分から質問ができるようになります。小学３年生からを対象とした、英語を楽しく１から学習するコースです。詳しくは、こちら（https://jp.glats.online/course/Elem1:2040）をご覧ください。\n\n ' +
                    '小学生の英会話2では、生活の中の身近な単語と簡単なフレーズを中心に学習を行います。物や人を説明する形容詞などを覚え、聞かれたことに答えるのみならず、自分の意見を少しずつ言えるようになります。また、レストランでのやりとりや道案内のシチュエーションを用いた実践的な英会話も学び始めます。スピーキングとリスニングだけではなく、宿題で読み書きの学習を行っていただくことで４技能の向上を目指します。詳しくはこちら（https://jp.glats.online/course/Elem2:2041）をご覧ください。\n\n ' +
                    'おとなMusio EnglishではAKAオリジナルの教材をもとにした授業を受けることができます。\n ' +
                    '「Hawaii」では、海外旅行の際に使用できる日常的な英会話を学ぶことができます。旅行に行く際に必須となる「飛行機での会話」や「レストランでのオーダー」など、海外旅行に行った際に役立つ表現を主に学びます。\n ' +
                    '「Business」では、ビジネス面で使用する英会話を学ぶことができます。\n ' +
                    'ビジネスならではの表現法や、よく使われる構文などを主に学び実際にビジネスで英語が必要な際に使用できるよう学習します。',
            },
            {
                question: 'テストが不合格の場合はどうなりますか？',
                answer:
                    'テストの合否は、講師が判断します。\n ' +
                    '講師はテストを通じて、ここまでのどのレッスンの内容がまだ身についていないかを判断します。\n ' +
                    '不合格、つまり「まだ身についていない」と判断した場合は、次回のレッスンでその内容に該当するレッスンを再度受講していただきます。\n ' +
                    'テストは、「他人と比較されるもの」ではなく、あくまで「しっかり身についているか」を確認するためのものですので、構えずにリラックスして取り組んでいただければと思います。',
            },
            {
                question: '1回のレッスンは何分ですか？',
                answer: '25分です。',
            },
            {
                question: '講師は固定制ですか？',
                answer: 'いいえ。ご予約の都度、講師をお選びいただきます。',
            },
            {
                question: '一つのコーのボリュームはどのくらいですか。',
                answer:
                    '小学生の英会話1は全38回、小学生の英会話2は全47回となっております。\n ' +
                    '週2回程度のペースで受講した場合、1つのコースを終えるのに5〜6ヶ月程度が目安となります。\n ' +
                    'おとなのMusio Englishプランの教材に対応をしている「Hawaii」「Business」では全52回となっております。こちらを週２ペースで受講した場合、一つのコースを終えるのに6~7ヶ月程度が目安となります。\n ' +
                    'しかし、教材は月に４冊を１年間にわたってお渡しいたしますので、教材のペースに合わせKimini英会話を受講されることをお勧めいたします。',
            },
            {
                question: '受講しているKimini英会話のコースを変更・追加できますか？',
                answer:
                    'Kimini英会話の受講コースはKimini英会話へログイン後、「現在の進捗」下部にあります「他のコースを始める」よりいつでも開始することができます。',
            },
            {
                question: 'コースを途中から始めたり、特定のレッスンだけ受講することはできますか？',
                answer:
                    '学習効率を最大限に高めるため、コースは最初から順番に受講いただきたく形とさせていただいております。そのため、現在はコースを途中から進めたり、特定のレッスンだけを受講いただくことはできません。\n ' +
                    'ただし、レッスン開始時にその旨を講師にお伝えいただければ、そのように対応させていただくことは可能です。',
            },
            {
                question: '予習と復習はどんな内容でしょうか？',
                answer:
                    '英語学習ロボットMusioで次のレッスンや習ったレッスンの単語やフレーズの発音練習と発音チェックができます。予習ではオンライン英会話での会話練習を、復習ではオンライン英会話で学んだ内容の定着を図ります。',
            },
            {
                question:
                    '小学生コースは小学3年生からが対象となっていますが、3年生未満でも受講できますか？',
                answer:
                    'はい。ご受講いただけます。3年生というのはあくまでも目安でございます。\n ' +
                    '小学1～2年生や就学前のお子さまが問題なく受講されているケースもございます。',
            },
        ],
    },
    {
        title: 'オンライン英会話/PCや受講環境について',
        q: [
            {
                question: '受講する機器の指定はありますか？',
                answer:
                    '受講いただける機器と環境は以下となります。\n ' +
                    'PCで受講される場合\n ' +
                    '●Windows(Windows8以降）；Google Chrome, Firefox, Microsoft Edge\n ' +
                    '●Mac（mac OS X Mavericks 10.9以降）：Google Chrome, Firefox, Microsoft Edge, Safari\n\n ' +
                    'スマートフォン・タブレットで受講される場合\n ' +
                    '●iOS/iPadOS【iPhone/iPad】（iOS12.4.8以降）：Google Chrome, Firefox, Microsoft Edge, Safari\n ' +
                    '●Android（Android５.1.1以降）：Google Chrome, Firefox, Microsoft Edge\n\n ' +
                    '「ご利用環境の確認」\n ' +
                    '以下のリンクよりご利用端末のブラウザ・カメラ・マイクの動作を確認できます。\n ' +
                    'https://kimini.online/lms/checker/\n\n ' +
                    '＜必要な周辺機器＞\n ' +
                    '内蔵カメラ、もしくは外付けカメラ\n ' +
                    'ヘッドセット（マイク、スピーカー、マイク付イヤフォン）\n\n ' +
                    '＜ネットワーク＞\n ' +
                    '通信帯域 ５Mbps以上推奨 ※Wifi(無線LAN)よりも有線LANを推奨',
            },
            {
                question: 'パソコンでなく、スマートフォンやタブレットでも受講できますか？',
                answer:
                    '下記のブラウザにてご利用いただけます。\n ' +
                    '●iOS/iPadOS【iPhone/iPad】（iOS12.4.8以降）：Google Chrome, Firefox, Microsoft Edge, Safari\n ' +
                    '●Android（Android５.1.1以降）：Google Chrome, Firefox, Microsoft Edge',
            },
            {
                question: 'カメラがなくてもレッスンを受けることはできますか？',
                answer:
                    '受講は可能ですが、効果的な学習のためにご利用いただくことを推奨しております。',
            },
            {
                question: 'ヘッドセットがなくてもレッスンを受けることはできますか？',
                answer:
                    'パソコンやスマートフォンに内蔵されているマイクとスピーカーで受講が可能です。\n ' +
                    'しかしながら、より安定した通話のためにヘッドセットのご利用をおすすめしております。\n ' +
                    'またiPhone等のスマートフォンに付属しているイヤフォンはほとんどの場合、マイクも内蔵されていますので、こちらも問題なくご利用いただけます。',
            },
            {
                question: 'ポケットWifiやスマートフォンの4G・5G回線でもレッスンを受講できますか？',
                answer:
                    'はい。可能です。\n ' +
                    'ただし据え置きのインターネット回線に比べてどうしても安定性に欠けますため、確実なレッスンの成立はお約束できません。\n ' +
                    'またビデオ通話を行う都合上、それなりのデータ容量も消費いたしますので、その点もご了承ください。',
            },
            {
                question: '通信がうまくいかないので、代わりにSkypeを使ってレッスンできますか？',
                answer:
                    '大変申し訳ございませんが、Skypeその他の通信ソフトを使ってのレッスンはご提供いたしておりません。',
            },
            {
                question: '通信がうまくいかない場合の、よくある原因と対策を教えてください。',
                answer:
                    'PCで、ChromeまたはFirefoxでアクセスしていない\n ' +
                    'PCで受講いただく場合、ChromeまたはFirefoxをご用意いただく必要がありますが、インストールを行うだけなく、そのインストールしたChromeまたはFirefoxを開いて本サイトにアクセスしていただく必要があります。\n\n ' +
                    'カメラやマイクが許可されていない\n ' +
                    'カメラやマイクへの接続を許可いただかないと、通信が行えません。\n ' +
                    '「ご利用環境の確認」のページからご確認ください。\n\n ' +
                    'スピーカーがミュートになっている\n ' +
                    '講師の声が聞こえない場合、スピーカーやイヤフォンのボリュームがゼロ（ミュート）になっているケースがよくあります。\n\n ' +
                    'マイクがミュートになっている\n ' +
                    'こちらの声が講師に聞こえない場合、マイクのボリュームがゼロ（ミュート）になっているケースがよくあります。\n\n ' +
                    'レッスン画面を複数開いてしまっている\n ' +
                    'レッスン画面は複数開かないでください。\n ' +
                    '画面を開くたび、通信の容量と処理が２倍、３倍、...となりますため、通信が安定しなくなります。\n\n ' +
                    '「更新」を試していない\n ' +
                    '通信が安定しない場合、レッスン画面に表示されている「更新」ボタンを押してリフレッシュをお試しください。\n ' +
                    'ほとんどの場合は、これにより通信が改善されます。',
            },
        ],
    },
    {
        title: 'オンライン英会話/レッスンの予約とキャンセルについて',
        q: [
            {
                question: 'レッスンの予約方法を教えてください。',
                answer:
                    'レッスンの予約は、こちら（https://jp.glats.online/）よりログインして行ってください。\n ' +
                    'スマートフォンからでも、PCからでも、どちらからも行っていただけます。\n ' +
                    '「現在の進捗」から、次に取り組むべき単元に表示されている「レッスンを予約する」ボタンを押してレッスンを予約してください。\n ' +
                    'また、予約したレッスン時間が過ぎるとレッスンが消化されたことになり、次のレッスンが予約ができるようになります。',
            },
            {
                question: 'レッスンの予約はいつまでできますか？',
                answer: 'レッスン開始時刻の15分前まで予約が可能です。',
            },
            {
                question: '講師のスケジュールは何日先まで公開されますか？',
                answer: '当日から1週間（7日間）先までのスケジュールが公開されます。',
            },
            {
                question: 'レッスンを受けられる時間帯と日付はいつですか？',
                answer:
                    '毎日6時〜24時です。\n ' +
                    '※年に数度の休校日はレッスンを受講いただけませんので、ご了承ください。',
            },
            {
                question: '希望の時間に予約が取れますか？',
                answer:
                    '営業時間内に充分な講師をご用意しておりますが、必ずしも希望される特定の時間にレッスンが受講いただけることを保証するものではありません。',
            },
            {
                question: 'レッスンの予約時間は、フィリピン時間ではなく日本時間ですか？',
                answer:
                    '「アカウントの設定」で表示時刻の設定を変更していない限り、予約時間の表示は日本時間です。\n ' +
                    '日本国外にお住まいの方は、「アカウントの設定」から表示時刻を切り替えていただくことも可能です。',
            },
            {
                question: 'レッスンのキャンセルは何時間前まで可能ですか？',
                answer:
                    '予約済みレッスンのキャンセルはレッスン開始の30分前まで可能です。もしレッスン開始まで30分を切ったキャンセルの場合は、当日のプランの受講権利喪失または使用チケットを失効します。\n\n ' +
                    'なお、同じコース内でレッスン番号のみ変更したい場合は、キャンセルの必要はありません。レッスン開始時に講師にお伝えいただければ、その場で講師が教材・内容を調整させていただきます。\n\n ' +
                    '※１日にキャンセルできる回数は、５回までとさせていただいております。\n ' +
                    '※同じ日時かつ同じ講師の再予約は、システム上制限させていただいております。',
            },
            {
                question: 'レッスンを予約したのに受講できませんでした。振り替えられますか？',
                answer:
                    '予約したレッスンを受けられなかった場合、レッスンを受講した場合と同じく、受講可能回数が1回分消費されます。\n ' +
                    '振り替えのレッスンはいたしておりませんので、受講できない場合は予めキャンセルの手続きをお願いいたします。',
            },
            {
                question: 'レッスンチケットとは何ですか？',
                answer:
                    '講師の都合でレッスンがキャンセルになった場合や、講師側の非によりレッスンが成立しなかった場合などに付与される、レッスン予約用のチケットです。通常の予約制限を越えてご予約いただく事が可能です。\n\n ' +
                    'ただし、以下の場合はチケットは付与されません。\n\n ' +
                    '・講師側の回線に問題があると弊社で確認できなかった場合\n ' +
                    '・生徒様の遅刻・欠席・キャンセル忘れなど、講師側に責任がない場合\n ' +
                    '・レッスンを予約されていなかったり、予約しても受講されなかった場合',
            },
            {
                question: '2レッスンや3レッスンを連続して予約し、受講することはできますか？',
                answer:
                    '月4回・月8回・1日1回コースは1日1回までレッスンを受講していただけます。1日2回コースのみ1日2回まで受講可能でございます。',
            },
            {
                question: 'レッスン前に準備することはありますか？',
                answer:
                    'スムーズにレッスンを開始できるよう、以下の点をご確認ください。\n\n ' +
                    '・インターネットに接続していますか？\n ' +
                    '・カメラ、ヘッドセット（マイク付イヤフォン）は接続していますか？ お使いのPCがきちんと認識できていますか？\n ' +
                    '・マイクの音量設定が小さくなりすぎていないか、ミュートになっていませんか？\n ' +
                    '・スピーカーの音量設定が小さくなりすぎていないか、ミュートになっていませんか？',
            },
            {
                question: '現在の進捗で"途中"と表示されているのはどういう意味ですか？',
                answer:
                    '1回のレッスンで1つの単元がすべて終わらなかった場合、その単元のステータスは"途中"となります（講師が判断します）。次回のレッスンでは、その途中で終わったレッスンの続きから再開することになります。',
            },
        ],
    },
    {
        title: 'オンライン英会話/レッスンの内容について',
        q: [
            {
                question: '毎回同じ講師でレッスンを受けた方がよいですか？',
                answer:
                    '様々な講師とお話いただいた方が、より実践的で学習効果が高まります。\n ' +
                    '講師間で生徒様の学習履歴を共有していますので、講師が変わっても前回の続きからスムーズにレッスンが可能です。',
            },
            {
                question: '1回のレッスンが最後まで終わらなかったらどうなりますか？',
                answer:
                    '次回のレッスンでは、その続きからレッスンを再開します。\n ' +
                    '※ただしニューストークコースの場合は途中からのレッスンが困難なため、次回のレッスンでは次の単元に進みます\n ' +
                    'レッスンが途中で終わった場合、その進捗は"途中"と表示されます。',
            },
            {
                question: '前回受けたレッスンの状況を講師は把握していますか？',
                answer:
                    'はい。レッスンをスタートする前に、各講師は生徒様の過去の学習履歴を確認してからレッスンに臨みます。\n ' +
                    'レッスンの最初に「どこから始めるか」を毎回講師に伝えていただく必要はありませんので、ご安心ください。',
            },
            {
                question: '全ての講師がカメラを使用しますか？',
                answer: 'はい。使用します。',
            },
            {
                question: 'レッスン中のチャットの履歴は残りますか？',
                answer:
                    '残ります。レッスンの詳細ページに表示されますので、後で見返していただくことができます。',
            },
            {
                question: '講師とのレッスンを録画する機能はありますか？',
                answer: 'ありません。お客様自身で録画していただいて差し支えございません。',
            },
            {
                question: '自分で用意した教材を使ってレッスンしてもらうことはできますか？',
                answer:
                    '大変申し訳ございませんが、お持ちの教材によるレッスンはご提供いたしかねますので、ご了承ください。',
            },
            {
                question: '講師は日本語を話せますか？',
                answer:
                    '講師の中には日本語を一部理解する者もおりますが、英語でのコミュニケーションが会話力を伸ばすという考えのもと、使用を禁止しています。',
            },
        ],
    },
    {
        title: 'オンライン英会話/レッスンに関するトラブルについて',
        q: [
            {
                question: '時間になっても「レッスン開始」ボタンが現れません。',
                answer:
                    'レッスン開始時間の15分前にページ上部に「レッスン開始」ボタンが現れ、レッスン開始5分前にそのボタンが押せるようになります。\n ' +
                    'もし時間になってもボタンが現れない場合はページを再読み込みしてください。',
            },
            {
                question: 'レッスン時間を過ぎても、講師が現れません。',
                answer:
                    '●「予約中のレッスン」のページでご予約日時が間違いないか、キャンセルしていないかご確認ください。\n ' +
                    '●やむを得ず、レッスンが講師都合キャンセルとなった可能性があります。恐れ入りますが、サイト右上「お知らせ」またはメールをご確認ください。\n ' +
                    '●必要に応じて、レッスン画面の「更新」を押すか、ブラウザを閉じて改めてご入室ください。\n ' +
                    '●チャットをお試しください。講師または生徒様のネットワークが不安定でも、チャットが利用できるケースがあります。',
            },
            {
                question: '講師に自分の映像や音声が届きません。',
                answer:
                    'まずは、下記4点をご確認ください。\n ' +
                    '1.カメラ、ヘッドセット（マイク付イヤフォン）がきちんと接続されており、コンピューターが認識しているか確認してください。\n ' +
                    '2.マイクの音量設定が小さくないか、ミュートになっていないか確認してください。\n ' +
                    '3.スピーカーの音量設定が小さくないか、ミュートになっていないか確認してください。\n ' +
                    '4.画面上に表示されている「更新」ボタンを押して、レッスン画面の更新を行ってください。\n ' +
                    '上記でも解決しない場合は、「お問い合わせ」にてご連絡ください。追って回答させていただきます。',
            },
            {
                question: '通信環境のトラブルで受講できなかった場合、どうしたらよいですか？',
                answer:
                    'まずはkiminiオンライン英会話のお問合せ先（https://jp.glats.online/support/）よりご相談ください。事情をお聞きした上でご対応させていただきます。',
            },
            {
                question: '通信がうまくいかないので、代わりにSkypeを使ってレッスンできますか？',
                answer:
                    '大変申し訳ございませんが、Skypeその他の通信ソフトを使ってのレッスンはご提供いたしておりません。',
            },
            {
                question: 'レッスンを欠席した場合、ペナルティはありますか？',
                answer:
                    'ペナルティのようなものは一切ございません。\n ' +
                    'ご受講いただいた場合と同じく、プランの受講回数やチケットが消費されます。\n ' +
                    '受講回数の補填はできかねますのでご了承ください（時間のお間違え等も同様です）。\n ' +
                    'レッスン履歴は不成立として記録されます。\n ' +
                    '受講予定だったレッスン番号は、改めてご予約いただけます。\n ' +
                    '受講できないことが事前に分かっている場合は、キャンセルの手続きをオススメしております',
            },
            {
                question: 'レッスンに遅刻した場合、どうなりますか？',
                answer:
                    'レッスンの開始時刻から14分59秒までにご入室いただければ、レッスンをご受講いただくことができます。終了時刻は予定通りとなりますのでご了承ください。\n\n ' +
                    '生徒様のご入室が15分経過しても確認できない場合、講師はレッスンルームを退室いたします。この場合レッスン履歴は、生徒様のご欠席による不成立として記録されます。詳細は「レッスンを欠席した場合、ペナルティはありますか？」をご確認ください。',
            },
        ],
    },
    {
        title: 'お申込について',
        q: [
            {
                question: '電話、Email、Faxでの申込はできますか？',
                answer:
                    'お申込につきましては、こちらのWebサイトからのみの受付けとなっています。インターネットがお使いいただける環境にてWebサイトよりお申込ください。',
            },
            {
                question: '申込はいつでも行えますか？',
                answer:
                    'こちらのWebサイトを通じ、24時間365日お申込いただけます。ただし、Musioのお届けの手配につきましては営業時間内での対応となりますのであらかじめご了承ください。',
            },
            {
                question: '申込後にキャンセルはできますか？',
                answer: 'お申込後のキャンセルは承っておりません。お申込の際は十分ご注意ください。',
            },
            {
                question: '申し込み後にメールが届かないのはなぜですか？',
                answer:
                    '以下のような原因が考えられますのでご確認ください。\n\n ' +
                    '-ご登録のメールアドレスが間違っている\n ' +
                    '-迷惑メールボックスに入っている\n ' +
                    '-メールボックスの容量が一杯で受け取れなくなっている\n ' +
                    '-ドメイン指定受信をしている（「akaintelligence.com」を受信できるように設定してください。）\n ' +
                    '＊hotmailにてご登録の場合、Hotmailのセキュリティ保護によりAKAからのメールがはじかれる場合がございます。ご登録はHotmail以外を推奨しております。',
            },
        ],
    },
    {
        title: '会員登録について',
        q: [
            {
                question: '会員登録は必須ですか？',
                answer: 'Musio Englishは会員制のサービスのため、お申し込み時に会員登録が必須です。',
            },
            {
                question: 'どこで会員登録ができますか？',
                answer:
                    'Musio Englishのお申し込み時に表示される会員登録画面にてご登録いただきます。',
            },
        ],
    },
    {
        title: 'お支払いについて',
        q: [
            {
                question: '初期費用はどれくらいかかりますか？',
                answer:
                    '入会金やシステム利用料などの初期費用はかかりません。無料体験期間終了後に自動的に料金を決済させていただきます。料金は選択されたプランによって変わります。',
            },
            {
                question: 'サービス利用料とは何ですか？',
                answer:
                    'Musio Englishをご利用にあたり、選択された支払期間ごとに発生する利用料金のことです。サービス利用料金につきましてはプラン毎に異なります。',
            },
            {
                question: '往復配送料とはなんですか？',
                answer:
                    'Musioの往復の送料・手数料でございます。Musio Englishをご解約される際に、2,600円を決済させていただきます。',
            },
            {
                question: 'どのような支払い方法がありますか？',
                answer:
                    '現在はクレジットカードのみでのお支払いを受け付けております。ご利用になれるクレジットカードは、Visa、MasterCard、American Express、Diners、JCBでございます。',
            },
            {
                question:
                    'クレジットカードを持っていませんが、利用したい場合はどうしたらいいですか？',
                answer:
                    '現在のお支払い方法はクレジットカード決済のみご利用になれます。誠に申し訳ございませんが、ご利用希望の場合にはクレジットカードをご用意ください。',
            },
            {
                question: 'サービス利用料金以外の負担はありますか？',
                answer: 'ご解約の際に、往復配送料（2,600円）をご負担いただきます。',
            },
            {
                question: 'サービス利用料金が発生するタイミングはいつですか？',
                answer:
                    '無料体験期間の終了のタイミングで、初回のお引き落としの処理をさせていただきます。\n ' +
                    '次回以降の決済日は、初回決済日を起算日として、毎月/３ヶ月後/１年後同日が決済日となります。\n\n ' +
                    '例）支払い期間：毎月の場合→初回決済日(プラン加入日)：2月12日 → 毎月の決済日＝12日 \n ' +
                    '支払い期間３ヶ月の場合→初回決済日(プラン加入日)：2月12日 → 次回決済日＝5月12日 \n ' +
                    'なお、無料体験はMusio発送日より7日間です。',
            },
            {
                question: '契約の更新はどう行いますか？',
                answer:
                    '解約申請をされない限り、契約は自動で更新されます。また、更新の際にはお知らせはさせていただいておりません事、ご了承くださいませ。',
            },
            {
                question: '月の途中でサービスを利用終了した場合、その月の請求はどうなりますか？',
                answer:
                    '恐れ入りますが、日割り返金は行っておりません。\n ' +
                    '決済日はお客様ごとに異なりますので、決済日に近いお日にちで解約申請をしていただくことをお勧めいたします。\n ' +
                    '※無料体験期間の終了のタイミングで、初月のお引き落としの処理をさせていただきます。\n ' +
                    '次回以降の決済日は、初回決済日を起算日として、毎月同日が決済日となります。\n ' +
                    '例）初回決済日(プラン加入日)：2月12日 → 毎月の決済日＝12日',
            },
            {
                question: '長期プランを途中で解約した場合、返金はありますか？',
                answer:
                    'プラン、およびご利用期間に応じ返金金額を計算し、返金させていただきます。\n ' +
                    '\n ' +
                    '・3ヶ月プラン: トライアル終了日より1ヶ月以内の解約に限り、返金させていただきます。\n ' +
                    '→ (例) 返金額 = (利用料合計 ÷ 3) × 残りの月数 × 70%\n ' +
                    '\n ' +
                    '・12ヶ月プラン: トライアル終了日より3ヶ月以内の解約に限り、返金させていただきます。\n ' +
                    '→ (例) 返金額 = (利用料合計 ÷ 12) × 残りの月数 × 70%',
            },
            {
                question: '領収書はもらえますか？',
                answer:
                    '弊社からの領収書の発行はしておりません。クレジットカード会社のご使用明細または、注文確認メールをご利用ください。',
            },
            {
                question:
                    'クレジットカードの有効期限切れ、クレジットカードの限度額超過等で、月額利用料金の支払いに失敗した場合はどうすればよいですか？',
                answer:
                    '有効期限切れの場合はマイページからクレジットカード情報の更新を、限度額超過の場合は速やかにその解消をお願いいたします。なお、翌月も同じく月額利用料金のお支払いができなかった場合はご利用不継続とみなし、解約の手配をさせていただく場合がございます。',
            },
        ],
    },
    {
        title: '配送について',
        q: [
            {
                question: '申込からどれくらいで届きますか？',
                answer:
                    'お住いの地域により異なりますが、基本的にはお申込後1週間以内にお届けいたします。',
            },
            {
                question: '配送料はいくらですか？',
                answer: '全国一律の往復配送料・手数料をいただきます。（2,600円）',
            },
            {
                question: '配送料の支払いタイミングはいつですか？',
                answer: 'サービスのご解約時に決済させていただきます。',
            },
            {
                question: '商品配送エリアに制限はありますか？',
                answer:
                    '日本全国へ配送可能です。沖縄や離島の場合は配送にお時間がかかる場合がございますことご了承ください。',
            },
            {
                question: '配送日時の指定/変更はできますか？',
                answer: '恐れ入りますが、配送日時の指定/変更はお受けしておりません。',
            },
            {
                question: '配送業者の指定はできますか？',
                answer:
                    '配送業者は弊社指定の配送業者のみとなっております。大変恐れ入りますが、指定はできませんのであらかじめご了承ください。',
            },
            {
                question: '配送時の商品の箱を捨ててもいいですか？',
                answer:
                    '捨てても差し支えありません。ただし、商品が入っております内箱は、返送時に入れてご発送いただきますため捨てないでください。',
            },
        ],
    },
    {
        title: '利用終了・返却・休会、お客様による購入について',
        q: [
            {
                question: '商品の利用終了・返却はどのように行えばいいですか？',
                answer: 'マイページの「解約申請」よりお申し込みください。',
            },
            {
                question: '商品の返却の際に送料はかかりますか？',
                answer: '全国一律の往復配送料・手数料をいただきます。（2,600円）',
            },
            {
                question: '返却する際の梱包はどうしたらいいですか？',
                answer:
                    '商品や専用充電機器などをが商品が入っていた箱に入れ、梱包してご返送ください。商品と商品が入っていた箱が別々の状態ですと、追加配送料が発生する場合がありますので予めご了承ください。',
            },
            {
                question: '購入はいつからできますか？',
                answer:
                    'Musio Englishを一年間継続していただけますと、単体価格の半額でお求めいただけます。',
            },
            {
                question: '購入はどのように行えばいいですか？',
                answer: '弊社までご購入希望の旨ご連絡ください。',
            },
            {
                question: '休会はできますか？',
                answer:
                    '大変申し訳ございませんが、休会は受け付けておりません。一度ご解約いただく、またはそのままご継続いただく形となります。',
            },
        ],
    },
    {
        title: 'サイトの使用方法について',
        q: [
            {
                question: 'パスワードを忘れてしまいました。',
                answer:
                    'ログイン画面の「パスワードをお忘れの方はこちらから」より再設定してください。',
            },
            {
                question: '登録したメールアドレスを忘れてしまいました。',
                answer:
                    '弊社までご連絡ください。なお、その際は、お客様の本人確認のため、ご登録の氏名・住所・電話番号をお知らせください。',
            },
            {
                question: 'ログインできない場合はどうすればいいですか？',
                answer:
                    '以下の原因が考えられますのでご確認ください。\n\n ' +
                    '- メールアドレスに誤りがある\n ' +
                    '　大文字小文字のご入力や＠以降のドメイン名に間違えがないかご確認ください。もしくは、お心当たりの他のメールアドレスでお試しください。ご登録したメールアドレスがご不明の場合には、お問い合せ先までご登録の氏名・住所・電話番号をご記入の上、お問い合わせください。\n\n ' +
                    '- パスワードに誤りがある\n ' +
                    '　大文字小文字のご入力に間違えがないかご確認ください。パスワードをお忘れの場合には、お問い合わせ先までご連絡の上、IDをお伝えいただきパスワードの再発行を行ってください。\n\n ' +
                    '- 登録が完了していない\n ' +
                    '　弊社から「会員登録が完了しました」という件名のメールを受信いただいていない場合、まだ登録が完了していない状態です。お手数ですが、再度会員登録のお手続きをお願いいたします。',
            },
            {
                question: 'ひとりで複数のアカウントを作ることはできますか？',
                answer:
                    'ご登録いただくアカウント（IDとパスワード）は、おひとり様1つとなっております。',
            },
            {
                question: '一つのアカウントを兄弟など複数人で使用することは可能でしょうか？',
                answer:
                    'いいえ。１アカウントお一人様までとさせていただいていますので、複数名様でご利用を希望される場合は、別途アカウントをお申し込みください。',
            },
            {
                question: '登録しているクレジットカードの変更は可能ですか？',
                answer: 'マイページの「決済カード」よりご変更いただけます。',
            },
            {
                question: '退会するにはどのようすればいいですか？',
                answer: 'マイページの「解約申請」より、お手続きを行ってください。',
            },
        ],
    },
]
