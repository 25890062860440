
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as PriceUtils from '../plugins/priceUtils'

@Component
export default class ChoosePlanEsatJPrice extends Vue {
    @Prop() price!: number | 0

    get totalPrice() {
        return PriceUtils.numberWithCommas(this.price)
    }
}
