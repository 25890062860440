
// @ts-nocheck
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class RegistrationCompleted extends Vue {
    completionImgUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/Completion.png'
    fukurouLab = `https://x-api.cir.io/postback/circuit_x_beacon?oid=${this.$store.state.customer.orderId}&ma_campaign_id=1776`

    mounted() {
        // @ts-ignore
        this.$analytics.fbq.init('2953106004732295')
        // @ts-ignore
        this.$analytics.fbq.event('PageView')
        // @ts-ignore
        window.dataLayer = window.dataLayer || []
        // @ts-ignore
        dataLayer.push({
            a8: this.$store.state.authentication.a8,
            salesOrder: this.$store.state.customer.orderId,
        })

        // run affiliate script
        _rt.sid = 7118
        _rt.pid = 10121
        _rt.price = 0
        _rt.reward = -1
        _rt.cname = ''
        _rt.ctel = ''
        _rt.cemail = ''
        _rt.cinfo = this.$store.state.customer.orderId // store_v2__order-historyのid
        rt_tracktag()

        // sherry ask => affiliate script v2
        !(function(e, t, n, r, a, c, s) {
            ;(e.TrackerObject = a),
                (e[a] =
                    e[a] ||
                    function() {
                        ;(e[a].q = e[a].q || []).push(arguments)
                    }),
                (e[a].l = 1 * new Date()),
                (c = t.createElement(n)),
                (s = t.getElementsByTagName(n)[0]),
                (c.async = 1),
                (c.src = r + '?_t=' + e[a].l),
                s.parentNode.insertBefore(c, s)
        })(window, document, 'script', 'https://script-ad.mobadme.jp/js/tracker.js', 'trk')
        trk('configure', 'uId', this.$store.state.customer.orderId)
        trk('configure', 'pId', '1')
        trk('configure', 'hash', '')
        trk('send', '48510')
    }
}
