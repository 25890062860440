import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: colors.grey.darken4,
                secondary: colors.grey.darken1,
                accent: colors.blue.accent4,
                error: colors.red.accent3,

                background: colors.shades.white,
            },
        },
    },
}

export default new Vuetify(opts)
