
import { Vue, Component } from 'vue-property-decorator'
import PaymentCardSection from './PaymentCardSection.vue'

@Component({
    components: {
        PaymentCardSection,
    },
})
export default class MyPagePaymentCard extends Vue {}
