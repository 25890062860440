
import { Vue, Component } from 'vue-property-decorator'
import Instruction from '../components/Instruction.vue'

@Component({
    components: {
        Instruction,
    },
})
export default class Plantable extends Vue {
    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
}
