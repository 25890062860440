
    import { Vue, Component } from 'vue-property-decorator'
    import EsatJIcon from './EsatJIcon.vue'
    
    @Component({
        components: {
            EsatJIcon
        }
    })

    export default class EsatJEffect extends Vue {

    }
