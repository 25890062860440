
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as PriceUtils from '../plugins/priceUtils'

@Component
export default class ChoosePlanPrice extends Vue {
    @Prop() price!: number | 0
    @Prop() optionId!: string | ''
    @Prop() originalPrice!: number | 0
    @Prop() discountRate!: number | 0
    @Prop() preDiscountPrice!: number | 0
    @Prop() contractMonth!: number | 0
    @Prop() package!: string | ''
    @Prop({ default: '#BFD2FF' }) selectedTabColor?: string
    @Prop() optionPrice!: number | 0
    @Prop() isPriceBefore?: boolean
    @Prop() priceChange!: number | 0

    get actualPrice() {
        return PriceUtils.numberWithCommas(this.price)
    }
    get halfActualPrice() {
        if (this.price !== 0) return PriceUtils.numberWithCommas(this.price * 0.5)
        return PriceUtils.numberWithCommas(this.price)
    }

    get optionActualPrice() {
        return PriceUtils.numberWithCommas(this.optionPrice)
    }

    get isSwitchPlanIncluded() {
        let currentRoute = this.$router.currentRoute
        return currentRoute.path.includes('switch-plan')
    }

    get percent() {
        let per = 0

        if (this.price === 4730) {
            per = 26
        } else if (this.price === 5610) {
            per = 14
        }

        return per
    }

    get percentOverThreeMonth() {
        let per = 0

        if (this.price === 13200) {
            per = 15
        } else if (this.price === 16500) {
            per = 3
        } else if (this.price === 55000) {
            per = 7
        }

        return per
    }
    get totalPrice() {
        if (
            this.price == null ||
            this.price === 0 ||
            this.optionId == null ||
            this.optionId === ''
        ) {
            return 0
        }
        let totalPrice = 0
        if (this.preDiscountPrice) {
            totalPrice = this.preDiscountPrice
        } else if (this.optionId === 'ME_Plan_3_1') {
            totalPrice = 120000 + this.originalPrice * this.contractMonth
        } else if (
            this.optionId === 'ME_Plan_3_2_1' ||
            this.optionId === 'ME_Plan_3_2_2' ||
            this.optionId === 'ME_Plan_3_2_3'
        ) {
            totalPrice = 150000 + this.originalPrice * this.contractMonth
        } else if (this.optionId.slice(0, 5) === 'adult') {
            totalPrice = this.price
        } else {
            totalPrice = this.originalPrice * this.contractMonth
        }
        return PriceUtils.numberWithCommas(totalPrice)
    }
}
