
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ChooseMultiPlanTabKids extends Vue {
    @Prop() title!: string
    @Prop({
        default: { color: '#087426', background: '#E2FFDD' },
    })
    selectedTabColor!: {
        background: string
        color: string
    }
    selectedTabs: string[] = ['選択しない']

    @Prop() tabValue! : string
    tabValueLocal: string = ''

    // @Watch('tabValue', { immediate: true, deep: false })
    // onTabValueChange(newValue: string, oldValue: string) {
    //     // 변경을 처리하고 로컬 데이터를 업데이트하거나 필요한 작업 수행
    //     this.tabValueLocal = newValue
    //     console.log('cc', this.tabValue)

    //     if (this.tabValueLocal === 'NEW CROWN Connect') {
    //         this.toggleChecked('NEW CROWN')
    //         this.toggleChecked('Connect')
    //     } else if (this.tabValueLocal === 'NEW CROWN' || this.tabValueLocal === 'Connect') {
    //         this.toggleChecked(this.tabValueLocal)
    //     }
    // }

    @Emit('returnTabValue')
    emitReturnObtionBook() {
        if (this.selectedTabs.length === 2) {
            return 'NEW CROWN Connect'
        } else if (this.selectedTabs.includes('NEW CROWN')) {
            return 'NEW CROWN'
        } else if (this.selectedTabs.includes('Connect')) {
            return 'Connect'
        } else {
            return '選択しない'
        }
    }

    isChecked(name: string) {
        return this.selectedTabs.includes(name)
    }

    toggleChecked(name: string) {
        if (name === '選択しない') {
            this.selectedTabs = [name]
        } else {
            if (this.selectedTabs.includes(name)) {
                this.selectedTabs = this.selectedTabs.filter(item => item !== name)
            } else {
                if (this.selectedTabs.includes('選択しない')) {
                    this.selectedTabs = [name]
                } else {
                    this.selectedTabs.push(name)
                }
            }
        }
        // console.log(this.selectedTabs)
            this.emitReturnObtionBook()   
    }
}
