
import { Vue, Component } from 'vue-property-decorator'
import Introduction from '../components/Introduction.vue'
import MarketingPoints from '../components/MarketingPoints.vue'
import LessonContents from '../components/LessonContents.vue'
import FunctionList from '../components/FunctionList.vue'
import MediaCoverage from '../components/MediaCoverage.vue'
import UserInterviews from '../components/UserInterviews.vue'
import PriceOverview from '../components/PriceOverview.vue'
import FAQSection from '../components/FAQSection.vue'

@Component({
    components: {
        Introduction,
        MarketingPoints,
        LessonContents,
        FunctionList,
        MediaCoverage,
        UserInterviews,
        PriceOverview,
        FAQSection,
    },
})
export default class Landing extends Vue {}
