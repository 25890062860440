
import { Vue, Component } from 'vue-property-decorator'
import EsatJAttack from './EsatJAttack.vue'
import EsatJChara from './EsatJChara.vue'

@Component({
    components: {
        EsatJAttack,
        EsatJChara,
    },
})
export default class PackageContentEsatJ extends Vue {}
