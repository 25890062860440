export enum ActionTypes {
    LOGIN_REQUEST = 'authentication/login_request',
    LOGIN_SUCCESS = 'authentication/login_success',
    LOGIN_FAILURE = 'authentication/login_failure',

    LOGOUT_REQUEST = 'authentication/logout_request',
    LOGOUT_SUCCESS = 'authentication/logout_success',
    LOGOUT_FAILURE = 'authentication/logout_failure',

    SIGNUP_REQUEST = 'authentication/signup_request',
    SIGNUP_SUCCESS = 'authentication/signup_success',
    SIGNUP_FAILURE = 'authentication/signup_failure',

    CHANGE_PASSWORD_REQUEST = 'authentication/change_password_request',
    CHANGE_PASSWORD_SUCCESS = 'authentication/change_password_success',
    CHANGE_PASSWORD_FAILURE = 'authentication/change_password_failure',

    FIND_PASSWORD_REQUEST = 'authentication/find_password_request',
    FIND_PASSWORD_SUCCESS = 'authentication/find_password_success',
    SEND_MAIL_FAILURE = 'authentication/send_mail_failure',

    HIDE_EVENT_MESSAGE = 'authentication/hide_event',
    HIDE_ERROR_MESSAGE = 'authentication/hide_error',
    SAVE_A_EIGHT_PARAM = 'SAVE_A_EIGHT_PARAM',
    MAKE_TEST_ACCOUNT = 'authentication/make_test_account',
}
