
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '@/store/modules/authentication/types'
import PasswordField from './PasswordField.vue'

@Component({
    components: {
        PasswordField,
    },
})
export default class MyPageChangePassword extends Vue {
    valid = false
    password = ''

    submit(): void {
        if ((this.$refs['form'] as HTMLFormElement).validate()) {
            this.$store.dispatch(ActionTypes.CHANGE_PASSWORD_REQUEST, {
                password: this.password,
            })
        }
    }
}
