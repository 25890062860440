
import { Vue, Component, Watch } from 'vue-property-decorator'
import MyPageUserInfo from '../components/MyPageUserInfo.vue'
import MyPageChangePassword from '../components/MyPageChangePassword.vue'
import MyPageSwitchPlan from '../components/MyPageSwitchPlan.vue'
import MyPageShippingAddress from '../components/MyPageShippingAddress.vue'
import MyPagePaymentCard from '../components/MyPagePaymentCard.vue'
import MyPageCancellation from '../components/MyPageCancellation.vue'
import { ActionTypes } from '@/store/modules/customer/types'
import { ActionTypes as authentication } from '@/store/modules/authentication/types'
import * as PlanUtils from '../plugins/planUtils'
import * as Utils from '@/plugins/utils'
import LogoutButton from '../components/LogoutButton.vue'
import FloatingSupportIcon from '@/components/FloatingSupportIcon.vue'
import MejRegistrantion from '../components/MejRegistrantion.vue'
import MyPageCancellationEsatJ from './MyPacgeCancellationEsatJ.vue'
import MyPageRegistrantionME from './MyPageRegistrantionME.vue'
import MyPageUserInfoEsatJ from '../components/MyPageUserInfoEsatJ.vue'

@Component({
    components: {
        MyPageUserInfo,
        MyPageChangePassword,
        MyPageShippingAddress,
        MyPagePaymentCard,
        MyPageSwitchPlan,
        MyPageCancellation,
        LogoutButton,
        FloatingSupportIcon,
        MejRegistrantion,
        MyPageCancellationEsatJ,
        MyPageRegistrantionME,
        MyPageUserInfoEsatJ,
    },
})
export default class MyPage extends Vue {
    eventMessageSnackbar = false
    errorMessageSnackbar = false
    content = 0
    showPopup = false
    contents = [
        { title: 'userInfoEdit', url: 'user-info-edit' },
        { title: 'changePassword', url: 'change-password' },
        { title: 'shippingAddress', url: 'shipping-address' },
        { title: 'paymentCard', url: 'payment-card' },
        { title: 'switchPlan', url: 'switch-plan' },
        { title: 'cancellation', url: 'cancellation' },
    ]
    activePanel = null
    commonItems = [
        { title: 'changePassword', url: 'change-password' },
        { title: 'shippingAddress', url: 'shipping-address' },
        { title: 'paymentCard', url: 'payment-card' },
    ]
    kodomoItems = [
        { title: 'userInfoEdit', url: 'user-info-edit' },
        { title: 'switchPlan', url: 'switch-plan' },
        { title: 'MejRegistrantion', url: 'registration-mej' },
        { title: 'reserveKmini', url: 'https://kimini.online/plus/lesson/reserve/', new: true },
        {
            title: 'MusioInstruction',
            url: 'https://drive.google.com/file/d/1uudfJFQJuh9AFmGADhy_lNAZcESDyPm1/view',
            new: true,
        },
        { title: 'cancellation', url: 'cancellation' },
    ]
    musioItems = [
        { title: 'mejUserInfo', url: 'mej-user-info' },
        { title: 'MeRegistrantion', url: 'registration-me' },
        { title: 'cancellationMej', url: 'cancellation-mej' },
    ]

    async created() {
        const isHistoryFound = await this.$store.dispatch(ActionTypes.GET_ORDER_HISTORY_REQUEST)
        const is_admin = Utils.adminCheck(this.$store.state.authentication.email)
        if (!isHistoryFound && !is_admin) {
            this.$router.push('/registration')
        }
        await this.$store.dispatch(ActionTypes.GET_USERDATA)
        const tempProduct = PlanUtils.getPlanFormatToSaveFromUserdata(
            this.$store.state.customer.userdata
        )
        this.$store.commit(ActionTypes.SAVE_OPTIONS, { product: tempProduct })

        await this.$store.dispatch(ActionTypes.GET_CARD_REQUEST)
    }

    onConfirmClicked(): void {
        this.$store.dispatch(ActionTypes.NAVIGATE_TO_KIMINI)
        this.showPopup = false
    }

    @Watch('$store.state.customer.eventMessage')
    async onEventMessageReceived(val: string, oldVal: string) {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.eventMessageSnackbar = !!this.$store.state.customer.eventMessage
        await this.delay(2000)
        this.$store.commit(ActionTypes.HIDE_EVENT_MESSAGE)
    }

    @Watch('$store.state.customer.errorMessage')
    async onErrorMessageReceived(val: string, oldVal: string) {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.errorMessageSnackbar = !!this.$store.state.customer.errorMessage
        await this.delay(2000)
        this.$store.commit(ActionTypes.HIDE_ERROR_MESSAGE)
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    logout(): void {
        this.$store.dispatch(authentication.LOGOUT_REQUEST).then(() => {
            this.$store.commit(ActionTypes.STATE_RESET)
            this.$router.push('/')
        })
    }
    kiminiUrl = 'https://kimini.online/plus/lesson/reserve/'

    blogUrl = 'https://blog.musioenglish.com/'

    navLogoUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/pc_navbar_logo.png'

    navLogoMobileUrl =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/ME_logo.svg'

    manual = 'https://drive.google.com/file/d/1uudfJFQJuh9AFmGADhy_lNAZcESDyPm1/view'

    data() {
        return {
            drawer: false,
        }
    }
    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
    goTo(url: string): void {
        window.open(url, '_blank')
    }
    scrollMove(to: string) {
        // 他のページからホームアイコンをクリックした場合
        if (to == '/' && this.$router.currentRoute.path !== '/') {
            this.navigate(to)
        } else {
            if (to == '/') {
                this.$vuetify.goTo(0)
            } else {
                this.$vuetify.goTo(to)
            }
        }
    }
    updateContent(index: number) {
        this.content = index
    }
}
