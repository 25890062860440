import { RootState } from '@/store'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { initialState, AuthenticationState } from './state'

export * from './state'

export const store: Module<AuthenticationState, RootState> = {
    state: initialState,
    actions,
    getters,
    mutations,
}
