export interface Card {
    cardHolder: string
    cardNumber: string
    expirationDate: string
    brand: string
}

export interface CustomerState {
    products: []
    userdata: any
    addresses: any[]
    selectedAddress: string
    card: Card | null
    isProcessingPayment: boolean
    eventMessage?: string
    errorMessage?: string
    isSignedUp: boolean
    orderId: string
    selectedProduct: any
    isAdmin: boolean
    settingStep: any
}

export const initialState = (): CustomerState => {
    return {
        products: [],
        userdata: null,
        addresses: [],
        selectedAddress: '',
        card: null,
        isProcessingPayment: false,
        eventMessage: undefined,
        errorMessage: undefined,
        isSignedUp: false,
        orderId: '',
        selectedProduct: null,
        isAdmin: false,
        settingStep: undefined,
    }
}
