
import { Vue, Component, Watch } from 'vue-property-decorator'
import SendMail from '@/components/Admin/SendMail.vue'
import SettingStep from '@/components/Admin/SettingStep.vue'
import Notification from '@/components/Admin/Notification.vue'
import { MOBILEWIDTH } from '@/utils/constants'
import AddAccount from '../components/Admin/AddAccount.vue'

@Component({
    components: {
        SendMail,
        SettingStep,
        Notification,
        AddAccount,
    },
})
export default class MyPage extends Vue {
    eventMessageSnackbar = false
    errorMessageSnackbar = false
    windowWidth = window.innerWidth

    contentIdx = 0 // initial route
    adminContents = [
        {
            title: 'SendMail',
            session: 'sendMail',
        },
        {
            title: 'SettingStep',
            session: 'settingStep',
        },
        {
            title: 'お知らせ',
            session: 'notification',
        },
        {
            title: 'Add Account',
            session: 'addAccount',
        },
    ]

    get content(): string {
        return this.adminContents[this.contentIdx].session
    }

    navigate(session: string): void {
        this.$router.push({ name: 'AdminContent', params: { session: session } }).catch(_err => {})
    }

    initContent(): void {
        this.adminContents.forEach((item, index) => {
            if (this.$router.currentRoute.path.includes(item.session)) {
                this.contentIdx = index
            }
        })
    }

    async created(): Promise<void> {
        this.initContent()
    }

    get isMobile(): boolean {
        return this.windowWidth < MOBILEWIDTH
    }

    @Watch('$store.state.product.eventMessage')
    async onEventMessageReceived(val: string, oldVal: string): Promise<void> {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.eventMessageSnackbar = !!this.$store.state.product.eventMessage
        await this.delay(2000)
    }

    @Watch('$store.state.product.errorMessage')
    async onErrorMessageReceived(val: string, oldVal: string): Promise<void> {
        // in order to display an error message every time the api call fails,
        // the timeout attribute of the snackbar component cannot be used.
        this.errorMessageSnackbar = !!this.$store.state.product.errorMessage
        await this.delay(2000)
    }

    delay(ms: number): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms))
    }
}
