
import { Vue, Component } from 'vue-property-decorator'
import { lists } from '../utils/campaignFaqs'

@Component
export default class CampaignVue extends Vue {
    youtubeUrl = 'https://www.youtube.com/embed/'
    faqLists = lists
    firstImg =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kids_mask_1.png'
    secondImg =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kids_mask_2.png'
    thirdImg =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kids_mask_3.png'
    fourthImg =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kids_mask_4.png'
    campaignForm = 'https://forms.gle/GJ5Zuqi3HfyBLN9j9'

    navigateToGoogleForm() {
        window.open(this.campaignForm, '_blank')
        this.$router.push('/')
    }
}
