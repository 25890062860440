var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',{staticClass:"button-container",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(_vm._s(_vm.$t('logout')))])],1),_c('v-row',{staticClass:"header-container",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"circle-layout",attrs:{"cols":"auto"}},[_c('v-container',{staticClass:"circle",class:[
                    _vm.getPath().includes('signup') ? 'gray-background' : 'white-background',
                ]},[_c('v-img',{attrs:{"src":_vm.signupStepUrl,"contain":""}})],1)],1),_c('v-col',{staticClass:"progress-font-size",attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" Step 1 ")])]),_c('v-row',{staticClass:"pre-formatted",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('registrationStepOne'))+" ")])])],1),_c('v-col',{staticClass:"arrow",attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":_vm.arrowUrl,"contain":""}})],1),_c('v-col',{staticClass:"circle-layout",attrs:{"cols":"auto"}},[_c('v-container',{staticClass:"circle",class:[
                    _vm.getPath().includes('registration') ? 'gray-background' : 'white-background',
                ]},[_c('v-img',{attrs:{"src":_vm.editStepUrl,"contain":""}})],1)],1),_c('v-col',{staticClass:"progress-font-size",attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" Step 2 ")])]),_c('v-row',{staticClass:"pre-formatted",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('registrationStepTwo'))+" ")])])],1),_c('v-col',{staticClass:"arrow",attrs:{"cols":"auto"}},[_c('v-img',{attrs:{"src":_vm.arrowUrl,"contain":""}})],1),_c('v-col',{staticClass:"circle-layout",attrs:{"cols":"auto"}},[_c('v-container',{staticClass:"circle",class:[
                    _vm.getPath().includes('complete') ? 'gray-background' : 'white-background',
                ]},[_c('v-img',{attrs:{"src":_vm.completeStepUrl,"contain":""}})],1)],1),_c('v-col',{staticClass:"progress-font-size",attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" Step 3 ")])]),_c('v-row',{staticClass:"pre-formatted",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"pre-formatted",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('registrationStepThree'))+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }