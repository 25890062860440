import { MutationTree } from 'vuex'
import { AuthenticationState } from './state'
import { ActionTypes } from './types'

export const mutations: MutationTree<AuthenticationState> = {
    [ActionTypes.LOGIN_SUCCESS]: (state: AuthenticationState, payload: AuthenticationState) => {
        state.token = payload.token
        state.email = payload.email
        state.name = payload.name
        state.eventMessage = payload.eventMessage
        state.errorMessage = payload.errorMessage
    },
    [ActionTypes.LOGIN_FAILURE]: (state: AuthenticationState, payload: AuthenticationState) => {
        state.token = payload.token
        state.email = payload.email
        state.name = payload.name
        state.eventMessage = payload.eventMessage
        state.errorMessage = payload.errorMessage
    },
    [ActionTypes.LOGOUT_SUCCESS]: (state: AuthenticationState) => {
        state.token = undefined
        state.email = undefined
        state.name = undefined
        state.eventMessage = 'logout'
    },
    [ActionTypes.SIGNUP_SUCCESS]: (state: AuthenticationState, payload: AuthenticationState) => {
        state.token = payload.token
        state.email = payload.email
        state.name = payload.name
        state.eventMessage = payload.eventMessage
        state.errorMessage = payload.errorMessage
    },
    [ActionTypes.SIGNUP_FAILURE]: (state: AuthenticationState, payload: AuthenticationState) => {
        state.token = payload.token
        state.email = payload.email
        state.name = payload.name
        state.eventMessage = payload.eventMessage
        state.errorMessage = payload.errorMessage
    },
    [ActionTypes.CHANGE_PASSWORD_SUCCESS]: (state: AuthenticationState) => {
        state.eventMessage = 'changePasswordSucceeded'
    },
    [ActionTypes.CHANGE_PASSWORD_FAILURE]: (state: AuthenticationState) => {
        state.errorMessage = 'changePasswordFailed'
    },
    [ActionTypes.FIND_PASSWORD_SUCCESS]: (state: AuthenticationState) => {
        state.eventMessage = '入力したメールアドレスにパスワード再設定リンクをお送りしました。'
    },
    [ActionTypes.SEND_MAIL_FAILURE]: (state: AuthenticationState) => {
        state.errorMessage = 'sendMailFailed'
    },
    [ActionTypes.HIDE_EVENT_MESSAGE]: (state: AuthenticationState) => {
        state.eventMessage = undefined
    },
    [ActionTypes.HIDE_ERROR_MESSAGE]: (state: AuthenticationState) => {
        state.errorMessage = undefined
    },
    [ActionTypes.SAVE_A_EIGHT_PARAM]: (state: AuthenticationState, payload: { param: string }) => {
        state.a8 = payload.param
    },
}
