
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EventCarousel extends Vue {
    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
}
