
import { Vue, Component } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'
import { ActionTypes as CustomerActionTypes } from '@/store/modules/customer/types'

@Component
export default class SupportForm extends Vue {
    form = {
        lastName: '',
        firstName: '',
        email: '',
        category: 'Musio English ユーザー',
        title: 'お支払いについて',
        contents: '',
    }

    submit_btn = {
        text: '送信',
        disabled: false,
    }

    categoryList = ['Musio English ユーザー', '加入検討中', 'その他', 'Musio ESAT-Jユーザー']
    titleList = [
        'Musio Englishについて',
        'お支払いについて',
        '解約方法、プラン変更について',
        'Musio製品について',
        'その他',
        'Musio ESAT-Jについて',
    ]

    rules = {
        required: (v: string) => !!v || i18n.t('thisFieldIsRequired'),
        email: (v: string) =>
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
            ) || '正しいメールアドレスを入力してください',
    }

    created(): void {
        // fill out user information automatically when logined
        if (this.$store.state.authentication) {
            this.form.email = this.$store.state.authentication.email
            this.form.firstName = this.$store.state.authentication.name?.split(' ')[0]
            this.form.lastName = this.$store.state.authentication.name?.split(' ')[1]
        }
    }

    async submit(): Promise<void> {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.submit_btn.text = '送信中'
            this.submit_btn.disabled = true

            const res = await this.$store.dispatch(CustomerActionTypes.SEND_SUPPORT_MAIL, {
                data: this.form,
            })

            if (res) {
                this.$router.replace('/support_confirm')
            } else {
                this.submit_btn.text = '送信'
                this.submit_btn.disabled = false
            }
        }
    }
}
