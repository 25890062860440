
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MyPageUserInfo extends Vue {
    userdata = this.$store.state.customer.userdata
    userName: string = '-'
    userEmail: string = '-'
    isJustOne = 0
    kiminiId: string = '-'

    async created() {
        // console.log('데이터' , this.userdata)
        if (!this.userdata) {
            console.log('no found userdata')
        }
        this.isJustOne = this.userdata?.length
        if (this.userdata.length !== 0) {
            this.userName = this.userdata[0].last_name + ' ' + this.userdata[0].first_name
            this.userEmail = this.userdata[0].email
        }
    }

    get planName() {
        if (this.isJustOne === 1 && !this.userdata[0].plan.includes('ESAT-J')) {
            this.kiminiId = this.userdata[0].kimini.id
            return this.userdata[0].plan
        } else if (this.isJustOne === 2) {
            if (this.userdata[0].plan.includes('ESAT-J')) {
                this.kiminiId = this.userdata[1].kimini.id
                return this.userdata[1].plan
            } else {
                this.kiminiId = this.userdata[0].kimini.id
                return this.userdata[0].plan
            }
        } else {
            return '-'
        }
    }

    get nextPayAt() {
        let timestamp = 0
        if (this.isJustOne === 1 && !this.userdata[0].plan.includes('ESAT-J')) {
            timestamp = this.userdata[0].next_pay_at
        } else if (this.isJustOne === 2) {
            if (this.userdata[0].plan.includes('ESAT-J')) {
                timestamp = this.userdata[1].next_pay_at
            } else {
                timestamp = this.userdata[0].next_pay_at
            }
        }

        if (timestamp === 0) {
            return '-'
        } else {
            return new Date(timestamp * 1000).toLocaleDateString()
        }
    }
}
