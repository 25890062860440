
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ActionTypes } from '../store/modules/customer/types'
import i18n from '@/plugins/i18n'

@Component
export default class EditAddressPopUp extends Vue {
    @Prop() addressInfo: any | undefined
    @PropSync('open', { default: false }) isPopUpOpen!: boolean

    valid = false

    form = {
        addressId: null,
        lastName: '',
        firstName: '',
        romanizedLastName: '',
        romanizedFirstName: '',
        addressZip: '',
        addressState: '',
        addressCity: '',
        addressDetails: '',
        buildingName: '',
        phoneNumber: '',
    }

    rules = {
        required: (value: any) => !!value || i18n.t('thisFieldIsRequired'),
        postalCode: (v: any) => /^\d{3}[\s.-]?\d{4}$/.test(v) || i18n.t('invalidPostalCode'),
        phone: (v: any) =>
            /^[+]?(\d{1,2})?[\s.-]?\(?\d{2,3}\)?[\s.-]?\d{3,4}[\s.-]?\d{4}$/.test(v) ||
            i18n.t('invalidPhoneNumber'),
        romanizedName: (v: any) =>
            /^(?=.{1,50}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/.test(v) ||
            i18n.t('invalidRomanizedName'),
    }

    created(): void {
        this.initializeFields()
    }

    initializeFields(): void {
        this.form.addressId = this.addressInfo?.id
        this.form.lastName = this.addressInfo?.last_name
        this.form.firstName = this.addressInfo?.first_name
        this.form.romanizedLastName = this.addressInfo?.furigana_sei
        this.form.romanizedFirstName = this.addressInfo?.furigana_mei
        this.form.addressZip = this.addressInfo?.address_zip
        this.form.addressState = this.addressInfo?.address_state
        this.form.addressCity = this.addressInfo?.address_city
        this.form.addressDetails = this.addressInfo?.address_line
        this.form.buildingName = this.addressInfo?.address_line2
        this.form.phoneNumber = this.addressInfo?.phone
    }

    onCancelClicked(): void {
        this.initializeFields()
        this.isPopUpOpen = false
    }

    onSaveClicked(): void {
        if (this.form.addressId) {
            this.editAddress()
        } else {
            this.createAddress()
        }
        this.isPopUpOpen = false
        const formRef = this.$refs['form'] as HTMLFormElement
        formRef.reset()
    }

    createAddress(): void {
        this.$store.dispatch(ActionTypes.ADD_ADDRESS, {
            lastName: this.form.lastName,
            firstName: this.form.firstName,
            romanizedLastName: this.form.romanizedLastName,
            romanizedFirstName: this.form.romanizedFirstName,
            addressZip: this.form.addressZip,
            addressState: this.form.addressState,
            addressCity: this.form.addressCity,
            addressDetails: this.form.addressDetails,
            buildingName: this.form.buildingName || null,
            phoneNumber: this.form.phoneNumber,
        })
    }

    editAddress(): void {
        this.$store.dispatch(ActionTypes.MODIFY_ADDRESS, {
            id: this.form.addressId,
            lastName: this.form.lastName,
            firstName: this.form.firstName,
            romanizedLastName: this.form.romanizedLastName,
            romanizedFirstName: this.form.romanizedFirstName,
            addressZip: this.form.addressZip,
            addressState: this.form.addressState,
            addressCity: this.form.addressCity,
            addressDetails: this.form.addressDetails,
            buildingName: this.form.buildingName || null,
            phoneNumber: this.form.phoneNumber,
        })
    }

    // TODO move the list of prefectures somewhere else.
    prefectures = [
        '北海道',
        '青森県',
        '青森県',
        '岩手県',
        '宮城県',
        '秋田県',
        '山形県',
        '福島県',
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '東京都',
        '神奈川県',
        '新潟県',
        '富山県',
        '石川県',
        '福井県',
        '山梨県',
        '長野県',
        '岐阜県',
        '静岡県',
        '愛知県',
        '三重県',
        '滋賀県',
        '京都府',
        '大阪府',
        '兵庫県',
        '奈良県',
        '和歌山県',
        '鳥取県',
        '島根県',
        '岡山県',
        '広島県',
        '山口県',
        '徳島県',
        '香川県',
        '愛媛県',
        '高知県',
        '福岡県',
        '佐賀県',
        '長崎県',
        '熊本県',
        '大分県',
        '宮崎県',
        '鹿児島県',
        '沖縄県',
    ]
}
