
import { Vue, Component } from 'vue-property-decorator'
import EsatJAttack from '../components/EsatJAttack.vue'
import EsatJEffect from '../components/EsatJEffect.vue'
import EsatJFree from '../components/EsatJFree.vue'
import EsatJIntroduce from '../components/EsatJIntroduce.vue'
import EsatJPlan from '../components/EsatJPlan.vue'
import EsatJPoint from '../components/EsatJPoint.vue'
import EsatJStep from '../components/EsatJStep.vue'
import Instruction from '../components/Instruction.vue'


@Component({
    components: {
        EsatJIntroduce,
        EsatJPoint,
        EsatJPlan,
        EsatJStep,
        EsatJAttack,
        EsatJFree,
        Instruction,
        EsatJEffect
    },
})
export default class EsatJ extends Vue {
    PreparingImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/Group+141.png'
    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
    openGooglePlayLink() {
        window.open('https://play.google.com/store/apps/details?id=kr.akaai.esat_android', '_blank')
    }
    openAppleStoreLink() {
        window.open('https://apps.apple.com/jp/app/musio-esat-j/id6446240178', '_blank')
    }
}
