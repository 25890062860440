
import { Vue, Component, Prop } from 'vue-property-decorator'
import FloatingSupportIcon from '@/components/FloatingSupportIcon.vue'
import EventCarousel from '@/components/EventCarousel.vue'

@Component({
    components: {
        FloatingSupportIcon,
        EventCarousel,
    },
})
export default class Introduction extends Vue {
    @Prop({ default: false }) readonly displayBanner!: boolean

    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }
    goTo(url: string): void {
        window.open(url, '_blank')
    }

    newEvent = false
}
