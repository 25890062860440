
import { Vue, Component, Emit } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
    @Emit('returnTabValue')
    whatchTab() {
        return this.birthday
    }

    birthday: string = ''
    choseYears: string = ''
    choseMonth: string = ''

    years: string[] = []
    months: string[] = [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
    ]

    updateChoseMonth() {
        if (this.choseYears !== '' && this.choseMonth !== '') {
            this.birthday = this.choseYears + this.choseMonth.slice(0, -1).padStart(2, '0')
        }
        this.whatchTab()
    }
    mounted() {
        const currentYear = 1900
        for (let year = new Date().getFullYear(); year >= currentYear; year--) {
            this.years.push(year.toString()) // 1900년부터 현재 연도까지 years 배열에 추가
        }
    }
}
