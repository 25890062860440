
import { Vue, Component, Watch } from 'vue-property-decorator'
import CardInfo from './CardInfo.vue'
import EditCardPopUp from './EditCardPopUp.vue'

@Component({
    components: {
        CardInfo,
        EditCardPopUp,
    },
})
export default class PaymentCardSection extends Vue {
    editCard = false
    registrationOnGoing = false
    isError = false

    buttonClick() {
        this.editCard = true
        this.isError = false
    }

    @Watch('isError')
    watchIsError() {
        this.registrationOnGoing = false
    }

    @Watch('$store.state.customer.card')
    watchCardState() {
        this.registrationOnGoing = false
    }
}
