export enum ActionTypes {
    STATE_RESET = 'customer/state_reset',
    NAVIGATE_TO_KIMINI = 'customer/navigate_to_kimini',
    START_PAYMENT = 'customer/start_payment',
    GET_PRODUCTS = 'customer/get_products',
    GET_USERDATA = 'customer/get_userdata',
    SELECT_ADDRESS = 'customer/select_address',
    GET_ADDRESSES = 'customer/get_addresses',
    ADD_ADDRESS = 'customer/add_address',
    MODIFY_ADDRESS = 'customer/modify_address',
    DELETE_ADDRESS = 'customer/delete_address',
    GET_CARD_REQUEST = 'customer/get_card_request',
    GET_CARD_SUCCESS = 'customer/get_card_success',
    GET_CARD_FAILURE = 'customer/get_card_failure',
    UPDATE_CARD_REQUEST = 'customer/update_card_request',
    UPDATE_CARD_SUCCESS = 'customer/update_card_success',
    UPDATE_CARD_FAILURE = 'customer/update_card_failure',
    REGISTRATION_REQUEST = 'customer/registration_request',
    REGISTRATION_SUCCESS = 'customer/registration_success',
    REGISTRATION_SUCCESS_ESATJ = 'customer/registration_success_esatj',
    REGISTRATION_FAILURE = 'customer/registration_failure',
    SWITCH_PLAN_REQUEST = 'customer/switch_plan_request',
    SWITCH_PLAN_SUCCESS = 'customer/switch_plan_success',
    SWITCH_PLAN_FAILTURE = 'customer/switch_plan_failure',
    CANCEL_PLAN_REQUEST = 'customer/cancel_plan_request',
    CANCEL_PLAN_SUCCESS = 'customer/cancel_plan_success',
    CANCEL_PLAN_FAILURE = 'customer/cancel_plan_failure',
    GET_ORDER_HISTORY_REQUEST = 'customer/GET_ORDER_HISTORY_REQUEST',
    GET_ORDER_HISTORY_SUCCESS = 'customer/GET_ORDER_HISTORY_SUCCESS',
    GET_ORDER_HISTORY_FAILURE = 'customer/GET_ORDER_HISTORY_FAILURE',
    HIDE_EVENT_MESSAGE = 'customer/hide_event',
    HIDE_ERROR_MESSAGE = 'customer/hide_error',
    SAVE_OPTIONS = 'customer/save_options',
    SEND_SUPPORT_MAIL = 'customer/send_support_mail',
    SEND_ADMIN_MAIL = 'customer/send_admin_mail',
    GET_STEP_MAIL = 'customer/get_step_mail',
    POST_STEP_MAIL = 'customer/post_step_mail',
    DELETE_STEP_MAIL = 'customer/delete_step_mail',
    PUT_STEP_MAIL = 'customer/put_step_mail',
}
