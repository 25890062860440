
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AdultUserInterviews extends Vue {
    users = [
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/adult/interview_1.png',
            position: 'center',
            text:
                'Musioとなら毎日続けられそうです。難しい内容もありますが、Musioが「Correct!」と言ってくれたときがとても嬉しくてどんどんチャレンジしたくなります。スキマ時間で英語マスター頑張ります。',
        },
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/adult/interview_2.png',
            position: 'center',
            text:
                '教材もハワイに関しての情報がたくさん載っていて、ハワイ気分を味わいながらできるのがとても良いです。英語は苦手だけど、1ヶ月に４冊のペースなら頑張れそう！ハワイに行くのを目標に毎日Musioと話したいと思います。',
        },
        {
            img:
                'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/adult/interview_3.png',
            position: 'right',
            text:
                'ゆるく続けられるのがとてもいい！1日のルーティンの中にMusioでの学習を入れることで無理なく続けられています！Musioと毎日学習して、週に1回Kimini英会話で実践しています。',
        },
    ]

    userInterviewTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/adult/voice_title.png'
}
