
import { Vue, Component } from 'vue-property-decorator'
import PackageContentCommonKidsFamily from '@/components/PackageContentCommonKidsFamily.vue'
import MarketingPoints from './OptionBook.vue'

@Component({
    components: {
        PackageContentCommonKidsFamily,
        MarketingPoints,
    },
})
export default class PackageContentKids extends Vue {
    packageContentKidsTitle =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kidsplan_title%402x.png'
    contentsKidsTitleImage =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/kidsplan_title%402x.png'
    featureMusio =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_2.webp'
    featureKimini =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_1.webp'
    featureSophy =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/feature_3.webp'
}
